import React from 'react';
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import { MultipleDownloadBar } from '../style';
import { Button, Buttons } from '../../../sdk/Button';
import Icons from '../Icons';

export default function MultipleDownloadBarComponent({ files, langJson, handleSetFiles, handleClearFiles }) {

  const [loading, setLoading] = React.useState(false);

  const getFileExtension = (url) => {
    if (url != null) {
      return url.split(".").pop();
    }
    return null;
  };

  const downloadImages = async () => {
    setLoading(true);
    try {
      const zip = new JSZip();

      await Promise.all(files.map(async (file, index) => {
        const response = await fetch(file.url);
        const blob = await response.blob();
        const fileExtension = getFileExtension(file.url);
        zip.file(`${file.title}.${fileExtension}`, blob);
      }));

      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, "NTXMedia.zip");
      setLoading(false);
    } catch (error) {
        setLoading(false);
        console.error("Error downloading images:", error);
    }
  };
  

  return (
    <MultipleDownloadBar>
      <div className="media_container">
        <div className="media_inner">
          <div className="media_items">
            {files.map((file) => (
              <div key={file._id} className="media_item" onClick={() => handleSetFiles(file)}>
                <div className="overlay">
                  <Icons icon="delete" />
                </div>
                <img src={file.thumb} alt={file.title} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
        <Buttons>
          <Button white onClick={handleClearFiles}>Borrar todo</Button>
          <Button loading={loading} onClick={downloadImages} primary>{langJson.descargar} ({files.length})</Button>
        </Buttons>
      </div> 
    </MultipleDownloadBar>
  )
}