import React from "react";

function FilterBrandsComponent({ brandId, setBrandId, setBrandName, brands }) {
    const handleSetbrand = (e) => {
        e.preventDefault();
        setBrandId(e.target.getAttribute("data-value"));
        setBrandName(e.target.text);
    };

    return (
        <>
            <ul>
                {brands.map((index) => (
                    <li
                        key={index._id}
                        className={brandId === index._id ? "is-active" : ""}
                    >
                        <a
                            href=""
                            data-value={index._id}
                            onClick={handleSetbrand}
                        >
                            {index.name}
                        </a>
                    </li>
                ))}
            </ul>
        </>
    );
}

export default FilterBrandsComponent;
