export default {
    successRequestingLink:
        "Success requesting link, you should receive the link to your email.",
    errorRequestingLink: "There was an error requesting your link",
    linkText:
        "If you don't remember your password, do you want us to send you a new entry link?",

    notNecesary: "Not Necessary",
    yes: "Yes",
    correoInvalido: "Email Invalid",

    correoInvalido: "Invalid email",
    cancelar: "Cancel",
    linkDeEntrada: "Request entry link",
    linkDeEntradaContrasena: "Recover Password",

    haveUpdated: "We have updated our company policies",
    helpUpdated: "To proceed please accept the new terms",
    pleaseAcceptTerms: "I accept the terms and conditions",
    pleaseAcceptCookiePolicy: "I accept the cookie policies",
    ternsAndConditions: "Terms and conditions",
    cookiePolicy: "Cookie Policy",
    logoutAndDontAccept: "Logout and don't use the platform",
    Accept: "Accept",
    loading: "Loading",
    newClient: "If you are a new client",
    registerNow: "Sign Up to Buy",
    requestLoginLink: "request an access link by email",
    ifYouPrefer: "If you prefer, you can",
};
