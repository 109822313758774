import styled from "styled-components";

export const Header = styled.header`
	height: 305px;
	background-color: #112c55;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	position: relative;

	h1 {
		font-size: 3rem;
		font-weight: 700;
		margin: 15px 0 5px 0;
	}

	h2 {
		font-size: 2rem;
		font-weight: 400;
	}

	.url {
		position: absolute;
		bottom: -19px;
		left: 50%;
    		transform: translate(-50%, 0);
		padding: 10px 20px;
		font-size: 1.5rem;
		font-weight: 400;
		background-color: #f7Fbff;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 48px;
		border: 1px solid #112c55;
		gap: 10px;

		span {
			color: #232323;
			text-decoration: none;
		}
	}
`;

export const ContainerHelp = styled.div`
	padding: 80px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 50px;

	a {
		text-decoration: none;
		color: inherit;
	}

	p {
		text-align: center;
		line-height: 28px;
		strong {
			font-weight: 700;
		}
	}

	h2 {
		color: ${(props) => props.theme.primaryDarkMode};
		text-align: center;
		font-size: 2.1rem;
		font-weight: 700;
	}

	.beneficios {
		ul {
			width: 250px;
			margin: auto;
			margin-top: 35px;
			display: flex;
			flex-direction: column;
			gap: 20px;

			li {
				display: flex;
				align-items: center;
				gap: 10px;

				svg {
					width: 50px;
					flex-shrink: 0;
					color: ${(props) => props.theme.primaryDarkMode};
				}
			}
		}

		span {
			font-weight: 700;
		}
	}

	.toolbox-buttons {
		display: flex;
		flex-direction: column;
		gap: 15px;
		margin-top: 30px;
	}
`;
