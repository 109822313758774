export default {
    title: "More than 1500 resources to download",
    placeholder: "What are you looking for?",
    latest: "Most recent items",
    images: "Photos",
    videos: "Videos",
    blogs: "Blogs",
    branding: "Branding",
    catalogs: "Catalogs",
    allBrands: "All Brands",
    nothingFound: "No media content was found with this search",
    recursos: "Resources",
    marcas: "Brands",
    filtrar: "Filter",
    descargar: "Download",
	categories: "Categories",
	collections: "Collections",
	campaigns: "Campaigns",
};
