import { getParameterByName } from "../../../utils";

export const getWindowSearch = () => {
    return typeof window !== "undefined" ? window.location.search : "";
};

export const getQueryStringValue = (key, queryString = getWindowSearch()) => {
    return getParameterByName(key);
};

const parse = (queryString) => {
    if (queryString === "") return {};

    return JSON.parse(
        '{"' +
            queryString
                .replace("?", "")
                .replace(/&/g, '","')
                .replace(/=/g, '":"') +
            '"}',
        function (key, value) {
            return key === "" ? value : decodeURIComponent(value);
        }
    );
};

const serialize = function (obj) {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
};

export const setQueryStringValue = (
    key,
    value,
    queryString = getWindowSearch()
) => {
    const values = parse(queryString);

    const newQsValue = serialize({ ...values, [key]: value });
    setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export const setQueryStringWithoutPageReload = (qsValue) => {
    if (typeof window !== "undefined") {
        const newurl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname +
            qsValue;

        window.history.pushState({ path: newurl }, "", newurl);
    }
};
