import React from "react";
import { Helmet } from "react-helmet";
import { Header, ContainerHelp } from "./style";
import MainTemplate from "../../templates/MainTemplate";
import withTracker from "../../../blueberry/connectPromise";
import ClockIcon from "../../components/icons/ClockIcon";
import CallCenterIcon from "../../components/icons/CallCenterIcon";
import AccesibilityIcon from "../../components/icons/AccesibilityIcon";
import { Button } from "../../sdk/Button";
import langEs from "./lang/es";
import langEn from "./lang/en";
import langPt from "./lang/pt";

function getLang(lang) {
	switch (lang) {
		case "es":
			return langEs;
		case "en":
			return langEn;
		case "pt":
			return langPt;
		default:
			return langEs;
	}
}

class ClaimHelp extends MainTemplate {
	renderBody() {
		const { lang, brandSlug } = this.props.match.params;
		const _t = getLang(lang);

		const handleUrlClick = (e) => {
			e.preventDefault();
			const url = `/${lang}${brandSlug ? `/${brandSlug}` : ""}/claims`;
			this.props.history.push(url);
		};

		return (
			<>
				<Helmet>
					<title>{_t.title}</title>
					<meta name="description" content={_t.description} />
				</Helmet>
				<Header>
					<svg width="83" height="83" viewBox="0 0 83 83" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M45.8433 3.34019C45.8433 2.29112 45.2111 1.34534 44.2421 0.943882C43.2727 0.542418 42.1571 0.764329 41.4153 1.50613L32.9131 10.0084C31.9002 11.0214 31.9002 12.6636 32.9131 13.6766L41.4153 22.1789C42.1571 22.9207 43.2727 23.1426 44.2421 22.7411C45.2111 22.3397 45.8433 21.3939 45.8433 20.3448V15.3025H72.2377C73.9862 15.3025 75.4034 16.72 75.4034 18.4685V59.4885C75.4034 61.237 73.9862 62.6546 72.2377 62.6546H37.9773C37.275 62.6546 36.5895 62.8683 36.0116 63.2674L18.745 75.1952V66.1129C18.745 64.2029 17.1967 62.6546 15.2867 62.6546H10.7649C9.01632 62.6546 7.59889 61.237 7.59889 59.4885V18.4685C7.59889 16.72 9.01632 15.3025 10.7648 15.3025H18.1874C20.0974 15.3025 21.6458 13.7542 21.6458 11.8442C21.6458 9.93419 20.0974 8.38586 18.1874 8.38586H10.7648C5.19635 8.38586 0.682213 12.9 0.682213 18.4685V59.4885C0.682213 65.0571 5.19635 69.5713 10.7649 69.5713H11.8284V75.474C11.8284 80.9323 17.956 84.1468 22.447 81.0443L39.0556 69.5713H72.2377C77.8059 69.5713 82.3201 65.0571 82.3201 59.4885V18.4685C82.3201 12.9 77.8059 8.38586 72.2377 8.38586H45.8433V3.34019ZM26.7358 40.2024C30.2842 40.2024 33.1607 37.3258 33.1607 33.7775C33.1607 30.2291 30.2842 27.3526 26.7358 27.3526C23.1875 27.3526 20.311 30.2291 20.311 33.7775C20.311 37.3258 23.1875 40.2024 26.7358 40.2024ZM62.6916 33.7775C62.6916 37.3258 59.815 40.2024 56.2667 40.2024C52.7185 40.2024 49.8418 37.3258 49.8418 33.7775C49.8418 30.2291 52.7185 27.3526 56.2667 27.3526C59.815 27.3526 62.6916 30.2291 62.6916 33.7775ZM39.019 49.6208C36.2935 45.6977 31.7582 43.0773 26.7316 43.0773C21.7051 43.0773 17.1699 45.6977 14.4443 49.6208C13.1185 51.5292 14.756 53.8047 17.0795 53.8047H36.3837C38.7074 53.8047 40.3446 51.5292 39.019 49.6208ZM56.2626 43.0773C61.2893 43.0773 65.8242 45.6977 68.55 49.6208C69.8756 51.5292 68.2381 53.8047 65.9148 53.8047H46.6104C44.2871 53.8047 42.6496 51.5292 43.9751 49.6208C46.7006 45.6977 51.2359 43.0773 56.2626 43.0773Z" fill="white" />
					</svg>
					<h1>{_t.header}</h1>
					<h2>{_t.subheader}</h2>
					<a href="##" onClick={handleUrlClick}>
						<div className="url">
							<svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M12.25 6.5H11.375V4.78571C11.375 2.42 9.415 0.5 7 0.5C4.585 0.5 2.625 2.42 2.625 4.78571V6.5H1.75C0.7875 6.5 0 7.27143 0 8.21429V16.7857C0 17.7286 0.7875 18.5 1.75 18.5H12.25C13.2125 18.5 14 17.7286 14 16.7857V8.21429C14 7.27143 13.2125 6.5 12.25 6.5ZM7 14.2143C6.0375 14.2143 5.25 13.4429 5.25 12.5C5.25 11.5571 6.0375 10.7857 7 10.7857C7.9625 10.7857 8.75 11.5571 8.75 12.5C8.75 13.4429 7.9625 14.2143 7 14.2143ZM9.7125 6.5H4.2875V4.78571C4.2875 3.32 5.50375 2.12857 7 2.12857C8.49625 2.12857 9.7125 3.32 9.7125 4.78571V6.5Z" fill="#BEBEBE" />
							</svg>
							<span>
								{`https://www.noritex.com/${lang}/claims`}
							</span>
						</div>
					</a>
				</Header>
				<ContainerHelp>
					<p>
						{_t.text1}
						<br />
						{_t.text2}
						<br />
						<strong><a href="##" onClick={handleUrlClick}>{_t.text3}</a></strong>
					</p>
					<div className="beneficios">
						<h2>{_t.benefits}</h2>
						<ul>
							<li>
								<ClockIcon />
								<span>{_t.benefits1}</span>
							</li>
							<li>
								<AccesibilityIcon />
								<span>{_t.benefits2}</span>
							</li>
							<li>
								<CallCenterIcon />
								<span>{_t.benefits3}</span>
							</li>
						</ul>
					</div>
					<div>
						<figure>
							<img
								src="https://www.noritex.com/uplds/2023/7/15/vTPNSP4hGagxg3h9Cclaim-help-mobile.jpg"
								srcSet="https://www.noritex.com/uplds/2023/7/15/vTPNSP4hGagxg3h9Cclaim-help-mobile.jpg 500w,
										https://www.noritex.com/uplds/2023/7/15/jh9enWWBatbvMr676claim-help.jpg 800w"
								sizes="(max-width: 500px) 100vw,
										600px"
								alt=""
							/>
						</figure>
					</div>
					<div>
						<h2>{_t.tools}</h2>
						<div className="toolbox-buttons">
							<a
								href="https://www.noritex.com/uplds/2023/7/15/GnuQDgSmpH5WeWjCyPOLITICA_DE_RECLAMOS_NORITEX_2023.pdf"
								target="_blank"
								rel="noreferrer"
							>
								<Button primary isFullWidth>{_t.tools1}</Button>
							</a>
							<a
								href="https://ntxvideos.s3.amazonaws.com/uplds/2023/7/15/8qzpdGWGRkdsYnbZDtutorial_de_reclamos.mp4"
								target="_blank"
								rel="noreferrer"
							>
								<Button primary isFullWidth>{_t.tools2}</Button>
							</a>
							<a
								href="https://www.noritex.com/uplds/2023/7/15/2eSqBoCngpviYZok9Manual_-_Reclamos_Web_V2.pdf"
								target="_blank"
								rel="noreferrer"
							>
								<Button primary isFullWidth>{_t.tools3}</Button>
							</a>
						</div>
					</div>
				</ContainerHelp>
			</>
		);
	}
}

export default withTracker()(ClaimHelp);
