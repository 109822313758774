import React from 'react';
import { saveAs } from "file-saver";
import Icons from "../Icons";

export default function DownloadButton({ media, langJson }) {
  const [isLoading, setIsLoading] = React.useState(false);

    const getFileExtension = (url) => {
      if (url != null) {
        return url.split(".").pop();
      }
      return null;
    };

    const getFilename = (url) => {
      if (url) {
        return url.substring(url.lastIndexOf("/") + 1);
      }
      return "";
    }

    const downloadMedia = (result) => {
        return new Promise(async (resolve) => {
            const filename = getFilename(result);

            // const blob = axios.get(result, { responseType: "blob" });

            let blob = await fetch(result).then((r) => r.blob());
            saveAs(blob, filename, { autoBom: true });
            // this.download(filename, result);
            resolve(filename);
        });
    };

    const handleMediaDownload = () => {
        window.analytics.track("Download Media - NTX Media", {
            fileExtension: getFileExtension(media.url),
        });
        setIsLoading(true);

        downloadMedia(media.url).then(() => {
            setIsLoading(false);
        });
    };

  return (
    <div className={`action ${isLoading ? 'is-loading' : ''}`} onClick={handleMediaDownload}><Icons icon={isLoading ? "loading" : "download"}/><span className='label'>{langJson.descargar}</span></div>
  )

}