import React from "react";
import { Helmet } from "react-helmet";
import MainTemplate from "../templates/MainTemplate";
import styled from "styled-components";
import Blueberry from "../../blueberry/index";
import withTracker from "../../blueberry/connect";
import ErrorMessage from "../components/ErrorMessage/index";
import InternalLink from "../components/InternalLink";
import Loader from "../components/Loader";
import EmptySpace from "../components/EmptySpace";

import { Button, Buttons } from "../sdk/Button";
import { Control, Field } from "../sdk/Form";
import { Section, Heading, Box } from "../sdk/Layout";
import {
	SmallContainer,
	InfoSection,
	AskForLink,
	ButtonExtended,
	StandAloneLoader,
	SectionExtended,
	ControlExtended,
	Divisor,
} from "./style";
import { getParameterByName } from "../utils";

import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";
import SEOFieldsHelmet, { websiteName } from "../utils/SEOFieldsHelmet";

function getLang(lang) {
	switch (lang) {
		case "es":
			return lang_es;
		case "en":
			return lang_en;
		case "pt":
			return lang_pt;
	}
}

class Login extends MainTemplate {
	constructor(props) {
		super(props);
		this.renderBody = this.renderBody.bind(this);
		Blueberry.setLang(props.match.params.lang);

		let locationShared =
			process.env.NODE_ENV === "production" ? true : true;

		if (typeof window !== "undefined" && navigator.geolocation == null) {
			locationShared = true;
			console.log(navigator.geolocation);
			console.log(navigator);
		}

		if (
			props.location.search.indexOf("?") > -1 &&
			props.location.search.indexOf("bypass=secret") > -1
		) {
			locationShared = true;
		}

		const redirect_url = props.location.search
			? getParameterByName("redirect_url", props.location.search)
			: null;

		this.state = {
			email: "",
			password: "",
			isLoading: false,
			successMessage: null,
			redirect_url: decodeURIComponent(redirect_url),
			location: {
				latitude: 0,
				longitude: 0,
			},
			locationShared,
			// locationShared: false,
			isLocationError: false,
			offerMagicLink: false,
			isRequestingLink: false,
			isProcessingLocation: false,
			isResumingLogin: true,
			isInProxy: false,
		};
	}

	behindProxy = () => {
		var proxyHeader = "via";
		var req = new XMLHttpRequest();
		req.open("GET", document.location, false);
		req.send();
		var header = req.getResponseHeader(proxyHeader);
		if (header) {
			this.setState({
				errorMessage: this._t("hasProxy"),
			});
		}
	};

	componentDidMount() {
		if (typeof window !== "undefined") {
			this.validateBrowser();
			// this.behindProxy();
		}

		const magicLink = getParameterByName(
			"magiclink",
			this.props.location.search,
		);

		if (magicLink) {
			this.setState({ isLoading: true });
			Blueberry.login({ token: magicLink, hasLink: true }).catch(
				(err) => {
					console.log(err);
					this.setState({ isLoading: false });
				},
			);
		} else if (this.props.location.pathname.indexOf("logout") > -1) {
			Blueberry.logout();
		} else {
			console.log("load");
			this.setState({ isLoading: true, isResumingLogin: true });
			Blueberry.resumeLogin()
				.then(() => {
					console.log("resp");
					this.setState({ isLoading: false, isResumingLogin: false });
				})
				.catch(() => {
					console.log("catch");
					this.setState({ isLoading: false, isResumingLogin: false });
				});

			if (this.props.store.user && this.props.store.user.id) {
				this.userForceMyAccount();
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.store.user &&
			this.props.store.user.id &&
			this.props.location.pathname.indexOf("logout") <= -1
		) {
			this.userForceMyAccount();
		}
	}

	getCustomT = (param) => {
		const { lang } = this.props.match.params;
		const langTerms = getLang(lang);

		return langTerms[param];
	};

	validateBrowser = () => {
		// Firefox 1.0+
		var ua = navigator.userAgent;
		/* MSIE used to detect old browsers and Trident used to newer ones*/

		var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

		if (is_ie) {
			this.setState({
				errorMessage: this._t("browserError"),
			});
		}
	};

	handleEnter = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			this.onSubmit();
		}
	};

	onSubmit = (e) => {
		if (e) e.preventDefault();
		const { email, password, location } = this.state;
		const self = this;
		// const login = asteroidObj.loginWithPassword({email, password})
		this.setState({ isLoading: true, successMessage: null });

		Blueberry.login({ email: email.trim(), password })
			.then((userId) => {
				this.setState({ isLoading: false });
				if (analytics)
					analytics.track("Signed In", {
						username: email,
					});

				setTimeout(() => {
					self.getCart();
					self.onSuccess(userId);
				}, 100);
			})
			.catch((error) => {
				console.log("There was an error:" + error.reason);
				console.log("There", error);
				let offerMagicLink = false;
				if (error.reason === "Incorrect password") {
					offerMagicLink = true;
				}
				this.setState({
					errorMessage: error.reason,
					isLoading: false,
					offerMagicLink,
				});
			});
	};

	hideRequestForLink = () => {
		this.setState({ offerMagicLink: false });
	};

	handleLinkRequest = (e) => {
		e.preventDefault();
		const { email } = this.state;

		if (email == "") {
			this.setState({
				errorMessage: this._t("errorEmail"),
			});
			return;
		}
		this.setState({ isRequestingLink: true, email: "" });
		Blueberry.requestMagicLink({ email: email.trim(), isWebsite: true })
			.then(() => {
				this.setState({
					isRequestingLink: false,
					offerMagicLink: false,
					errorMessage: null,
					successMessage: this.getCustomT("successRequestingLink"),
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					isRequestingLink: false,
					offerMagicLink: true,
					successMessage: null,
					successMessage: null,
					errorMessage:
						err && err.reason
							? err.reason
							: this.getCustomT("errorRequestingLink"),
				});
			});
	};

	userForceMyAccount = () => {
		this.onSuccess();
	};

	onSuccess = (error, response) => {
		const { redirect_url } = this.state;
		const lang = `/${this.getBasePath()}/`;

		if (
			typeof window !== "undefined" &&
			window.__REGISTERED_LOGIN_HANDLERS__
		) {
			window.__REGISTERED_LOGIN_HANDLERS__.map((index) => {
				index();
			});
		}

		// reset component loader on login
		localStorage.setItem("didErrorRefreshTest", "");

		if (
			redirect_url != null &&
			redirect_url !== "" &&
			redirect_url.indexOf(lang) > -1
		) {
			this.props.history.push(
				`${lang}account/check?redirect_url=${redirect_url}`,
			);
		} else {
			this.props.history.push(`${lang}account/check`);
		}
	};

	setValue = (e) => {
		let obj = {};
		let name = e.target.name;
		let value = e.target.value;
		let passwordError = [];
		if (name === "password") {
			var lowerCaseLetters = /[a-z]/g;
			var upperCaseLetters = /[A-Z]/g;
			var numbers = /[0-9]/g;
			if (!value.match(lowerCaseLetters))
				passwordError.push("Password must have a lowercase");
			if (!value.match(upperCaseLetters))
				passwordError.push("Password must have an uppercase");
			if (!value.match(numbers))
				passwordError.push("Password must have a number");
			if (value.length < 8)
				passwordError.push("Password must be longer than 8 characters");
			obj["passwordError"] = passwordError;
		}
		obj[name] = value;
		this.setState(obj);
	};

	renderIf(isReady, node, returnNull) {
		if (returnNull) return null;
		if (isReady) return node;
		return (
			<div>
				<Loader />
			</div>
		);
	}

	geoSuccess = (position) => {
		setTimeout(() => {
			this.setState({
				isProcessingLocation: false,
				locationShared: true,
				location: {
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
				},
			});

			if (analytics)
				analytics.track("Shared Location", {
					location: {
						latitude: position.coords.latitude,
						longitude: position.coords.longitude,
					},
				});
		}, 3000);
	};

	geoError = (err) => {
		if (err && err.message === "Timeout expired") {
			this.setState({ locationShared: true });
		}
		this.setState({
			isProcessingLocation: false,
			errorMessage: err.message,
			locationError: true,
		});
	};

	getCurrentLocation = () => {
		this.setState({
			isProcessingLocation: true,
			errorMessage: null,
			locationError: false,
		});
		if (typeof window !== "undefined")
			navigator.geolocation.getCurrentPosition(
				this.geoSuccess,
				this.geoError,
				{
					timeout: 10000,
				},
			);
	};

	renderLocation(locationShared, isProcessingLocation, errorMessage) {
		// if (isProcessingLocation) {
		//     return (
		//         <div>
		//             <AskForLink>
		//                 <p className="text-center">
		//                     {this._t("itTakes5seconds")}
		//                 </p>
		//             </AskForLink>
		//             <Loader />
		//         </div>
		//     );
		// }
		if (locationShared) return null;
		if (typeof window === "undefined" || navigator) {
			return (
				<Box>
					<p>
						{!isProcessingLocation
							? this._t("termsGeo")
							: this._t("itTakes5seconds")}
					</p>
					{errorMessage && (
						<ErrorMessage errorMessage={errorMessage} />
					)}
					<Buttons>
						<Button>{this._t("cancelGeo")}</Button>
						<Button
							primary
							onClick={this.getCurrentLocation}
							loading={isProcessingLocation}>
							{this._t("acceptGeo")}
						</Button>
					</Buttons>
				</Box>
			);
		}
	}

	renderBody() {
		const {
			email,
			password,
			errorMessage,
			successMessage,
			isLoading,
			locationShared,
			isProcessingLocation,
			isResumingLogin,
			offerMagicLink,
			isRequestingLink,
		} = this.state;

		const { lang } = this.props.match.params;

		const langJson = getLang(lang);

		const title = `Login - ${websiteName}`;
		const description =
			websiteName + " Login " + lang === "es"
				? "es una tienda en linea para comprar mayorista de decoracion, hogar y navidad. Nuestras marcas principales de decoracion son Santini y Concepts Life. En nuestra tienda podras encontrar variedad de muebles, figuras, arboles, y mas."
				: "it is an online store to buy decoration, home and Christmas in wholesale. Our main decoration brands are Santini and Concepts Life. In our store you can find a variety of furniture, figures, trees, and more.";

		return (
			<SectionExtended>
				<SEOFieldsHelmet
					seo={null}
					lang={lang}
					defaultTitle={title}
					defaultDescription={description}
				/>
				<SmallContainer>
					<div className="wrapper">
						<Heading isCentered>
							<h1>Login</h1>
						</Heading>
						{isResumingLogin ? (
							<StandAloneLoader>
								<div>{"  "}</div>
							</StandAloneLoader>
						) : null}

						{this.renderLocation(
							locationShared || isLoading,
							isProcessingLocation,
							errorMessage,
						)}
						{this.renderIf(
							true,
							<form
								aria-autocomplete="on"
								onSubmit={this.onSubmit}>
								<p>{this._t("loginInfo")}</p>
								{errorMessage || successMessage ? (
									<ErrorMessage
										errorMessage={
											errorMessage || successMessage
										}
										sucess={
											successMessage !== "" &&
											successMessage != null
										}
									/>
								) : null}

								<input
									type="hidden"
									name="checkout_url"
									defaultValue="/account"
								/>
								<Field>
									<label htmlFor="email">Email</label>
									<ControlExtended
										isExpanded
										loading={isRequestingLink}>
										<input
											name="email"
											id="email"
											tabIndex="0"
											autoComplete="username"
											value={email}
											onChange={this.setValue}
											onKeyPress={this.handleEnter}
											type="email"
											autoCorrect="off"
											autoCapitalize="off"
											aria-required="true"
											aria-label="email"
										/>
										<p>
											{langJson.ifYouPrefer}
											{"  "}
											<a onClick={this.handleLinkRequest}>
												{langJson.requestLoginLink}
												<Button
													loading={
														isRequestingLink
															? isRequestingLink
															: undefined
													}>
													{"  "}
												</Button>
											</a>
										</p>
									</ControlExtended>
								</Field>
								<Field>
									<label htmlFor="current-password">
										Password
									</label>
									<ControlExtended isExpanded>
										<input
											name="password"
											id="current-password"
											autoComplete="current-password"
											tabIndex="0"
											value={password}
											onChange={this.setValue}
											onKeyPress={this.handleEnter}
											type="password"
											autoCorrect="off"
											autoCapitalize="off"
											aria-required="true"
											aria-label="Password"
										/>
									</ControlExtended>
								</Field>
								<Field>
									<ButtonExtended
										isFullWidth
										primary
										loading={isLoading}
										onClick={this.onSubmit}>
										Login
									</ButtonExtended>
								</Field>
							</form>,
							!locationShared,
						)}

						{offerMagicLink && (
							<AskForLink>
								{this.getCustomT("linkText")}
								<Buttons>
									<Button onClick={this.hideRequestForLink}>
										{this.getCustomT("notNecesary")}
									</Button>
									<Button
										primary
										onClick={this.handleLinkRequest}
										loading={isRequestingLink}>
										{this.getCustomT("yes")}
									</Button>
								</Buttons>
							</AskForLink>
						)}

						<InfoSection>
							<InternalLink
								className="link"
								to="/account/recovery">
								{this._t("loginLink")}
							</InternalLink>
						</InfoSection>
						{/* <Divisor /> */}
					</div>
					<p>{langJson.newClient}</p>
					<Button onClick={this.gotoRegister} as="a" isFullWidth>
						{/* {this._t("comercialize")} */}
						{langJson.registerNow}
					</Button>
					{/* <EmptySpace /> */}
				</SmallContainer>
			</SectionExtended>
		);
	}
}

export default withTracker({})(Login);
