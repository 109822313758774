import styled from "styled-components";
import { Control } from "../../sdk/Form";
import { Tabs } from "../../sdk/Elements";
import { Column } from "../../sdk/Layout";

export const Hero = styled.div`
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5%;
    background-image: url(${(props) => props.bg});
    background-size: cover;
    background-position: center center;
    box-shadow: inset 0 -1000px 0 rgba(0, 0, 0, 0.2);
    color: white;
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
    position: relative;

    .hero__container {
        width: 100%;
        max-width: 620px;
        margin: auto;
        position: relative;
        .tags__container {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
        }
    }

    h1 {
      font-size: 30px;
      margin-bottom: 20px;
    }

    .logo {
        position: absolute;
        top: 16px;
        left: 16px;
        svg {
            color: white;
            width: 170px;
            height: auto;
        }
    }

    @media (max-width: 580px) {
        height: 300px;
        .logo {
            svg {
                width: 120px;
            }
        }
        h1,
        h2 {
            font-size: 20px;
        }
    }
`;

export const Search = styled.div`
    ${Control} {
        position: relative;
        input {
            height: 60px;
            padding-right: 60px;
        }
        button {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 60px;
            width: 60px;
            border: none;
            background: none;
            cursor: pointer;
            svg {
                width: 18px;
                color: #c4c4c4;
            }
            &:hover {
                svg {
                    color: gray;
                }
            }
        }
    }
`;

export const TabsExtended = styled(Tabs)`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    overflow-x: auto;
    ul {
        border-bottom-color: transparent;
        margin: auto;
        li {
            margin: 0 10px;
            a {
                color: white !important;
                border-bottom-width: 3px;
                border-bottom-color: transparent;
                opacity: 0.7;
            }
            &.is-active {
                a {
                    border-bottom-color: white !important;
                    opacity: 1;
                }
            }
        }
    }
`;

export const ColumnExtended = styled(Column)`
    position: relative;
    min-width: 34px;
    .column__toggle {
        display: none;
        &.is-filters-hidden {
            display: flex;
        }
        position: absolute;
        top: 8px;
        right: 0;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        z-index: 2;
        cursor: pointer;
        svg {
            width: 20px;
            color: ${(props) => props.theme.gray};
        }
        &:hover svg {
            color: ${(props) => props.theme.textDefault};
        }
        @media (max-width: 768px) {
            display: none !important;
        }
    }

    &:hover {
        .column__toggle {
            display: flex;
        }
    }
    @media (max-width: 768px) {
        padding-bottom: 8px;
    }
`;

export const Filters = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
	max-width: 200px;
    .filter__label {
        margin-bottom: 10px;
        font-weight: bold;
    }
    ul {
        li {
            &:not(:last-child) {
                margin-bottom: 12px;
            }
            a {
                color: ${(props) => props.theme.gray};
                display: flex;
                align-items: center;
                gap: 8px;
                transition: color 0.3s ease;
                &:hover {
                    color: ${(props) => props.theme.textDefault};
                }
                svg {
                    flex: none;
                    width: 24px;
                }
            }
            &.is-active {
                a {
                    color: ${(props) => props.theme.primary};
                }
            }
        }
    }
	ul.lower > li > a {
		text-transform: capitalize;
	}
    @media (max-width: 768px) {
		max-width: 100%;
        &:not(.is-open) {
            display: none;
        }
        &.is-open {
            padding-top: 16px;
        }
        gap: 16px;
    }
`;

export const MediaList = styled.div`
    column-count: 6;
    @media (max-width: 2150px) {
        column-count: 5;
    }
    @media (max-width: 1920px) {
        column-count: 4;
    }
    @media (max-width: 1440px) {
        column-count: 3;
    }
    @media (max-width: 1024px) {
        column-count: 2;
    }
    column-gap: 20px;
`;

export const MediaItem = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: ${(props) => props.theme.radio};
    &:not(:last-child) {
        margin-bottom: 20px;
    }
    figure {
        position: relative;
        width: 100%;
        img {
            display: block;
            width: 100%;
        }
        > div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    h3 {
        font-weight: normal;
        font-size: 14px;
    }
    &:hover {
        .inner {
            opacity: 1;
        }
    }

    .inner {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.57) 0%, rgba(0, 0, 0, 0.00) 48.09%, rgba(0, 0, 0, 0.57) 101.24%);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        transition: opacity 0.3s ease;
        @media (min-width: 769px) {
            opacity: 0;
        }
        &__top {
            color: white;
            .title {
                font-size: 16px;
                font-weight: bold;
            }
            .metadata {
                font-size: 14px;
                opacity: ${(props) => props.theme.gray};
                display: flex;
                align-items: center;
                gap: 8px;
                margin-top: 5px;
                li {
                    &:not(:last-child) {
                        &:after {
                            content: "•";
                            margin-left: 8px;
                        }
                    }
                }
            }
        }
        &__bottom {
            .actions {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 8px;
                
                .action {
                    min-width: 40px;
                    height: 40px;
                    padding: 0px 8px;
                    border-radius: 3px;
                    background-color: white;
                    color: ${(props) => props.theme.primary};
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                    font-size: 16px;
                    @media (max-width: 768px) {
                        &.hide-on-mobile {
                           display: none;
                        }
                    }
                    &.download {
                        width: 120px;
                    }
                    &.is-loading {
                        pointer-events: none;
                        svg {
                            @keyframes spin {
                                from {
                                    transform:rotate(0deg);
                                }
                                to {
                                    transform:rotate(360deg);
                                }
                            }
                            animation: spin 1s linear infinite;
                        }
                    }
                    svg {
                        width: 20px;
                    }
                    &:hover:not(.is-active) {
                        background-color: ${(props) => props.theme.grayLight};
                    }
                    &.is-active {
                        background-color: ${(props) => props.theme.primary};
                        color: white;
                    }
                    @media (max-width: 768px) {
                        .label {
                            display: none;
                        } 
                    }
                }
            }
        }
    }
`;

export const IconGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px ${(props) => props.theme.borderColor};
        border-radius: 3px;
        width: 40px;
        height: 40px;
        svg {
            width: 20px;
        }
    }
`;

export const Tags = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    padding-top: 10px;
    .tag {
        height: 34px;
        padding: 0px 8px;
        background-color: rgba(0,0,0,.5);
        border-radius: 3px;
        color: white;
        font-weight: bold;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        svg {
            width: 16px;
            color: white;
        }
    }
`;


export const ToggleFilterMobile = styled.div`
    display: none;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background-color: ${(props) => props.theme.grayLight};
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    svg {
        width: 20px;
        color: ${(props) => props.theme.primary};
    }
    @media (max-width: 768px) {
        display: flex;
    }
    &.is-open {
        svg {
            transform: rotate(180deg);
        }
    }
`;

export const MultipleDownloadBar = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: ${(props) => props.theme.white};
    z-index: 2;
    padding: 16px;
    box-shadow: 0px 0px 10px rgba(0,0,0,.2);
    display: grid;
    gap: 8px;
    align-items: center;
    grid-template-columns: 1fr auto;
    min-height: 82px;
    @media (max-width: 768px) {
        display: none;
    }
    .media_container {
        position: relative;
        height: 100%;
    }
    .media_inner {
        position: absolute;
        top: 0;
        left: 0;
        overflow-x: auto;
        width: 100%;
    }
    .media_items {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .media_item {
        width: 50px;
        height: 50px;
        border-radius: 3px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        flex: none;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: grid;
            place-content: center;
            background-color: rgba(0,0,0,.5);
            opacity: 0;
            svg {
                width: 30px;
                color: white;
            }
        }
        &:hover {
            .overlay {
                opacity: 1;
            }
        }
    }
`;