import React from "react";
import { Helmet } from "react-helmet";
import MainTemplate from "../templates/MainTemplate";
import Logo from "../components/Logo";
import Blueberry from "../../blueberry/index";
import withTracker from "../../blueberry/connect";
import ErrorMessage from "../components/ErrorMessage/index";
import InternalLink from "../components/InternalLink";
import Loader from "../components/Loader";

import { Button, Buttons } from "../sdk/Button";
import { Control, Field, Checkbox } from "../sdk/Form";
import { Section, Heading, Box } from "../sdk/Layout";
import {
    SmallContainer,
    InfoSection,
    AskForLink,
    StandAloneLoader,
    Wrapper,
    BoxExtended,
    LogoWrapper,
    Center,
    Header,
} from "./style";
import LoadLayerComponent from "../components/LoadLayer";
import { getParameterByName } from "../utils";

import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";
import { websiteName } from "../utils/SEOFieldsHelmet";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

class Login extends MainTemplate {
    constructor(props) {
        super(props);

        const redirect_url = props.location.search
            ? getParameterByName("redirect_url", props.location.search)
            : null;

        this.state = {
            redirect_url: decodeURIComponent(redirect_url),
            progress: 0,
        };
    }

    componentDidMount() {
        this.checkTerms();
    }

    setValue = (e) => {
        const { name, checked } = e.target;
        const obj = {};

        obj[name] = checked;
        this.setState(obj);
    };

    getTermsSlug = (lang) => {
        return {
            es: "terminos-y-condiciones",
            en: "terms-of-service",
            pt: "termos-e-condicoes",
        }[lang];
    };

    getCookieSlug = (lang) => {
        return {
            es: "cookies",
            en: "cookies",
            pt: "cookies",
        }[lang];
    };

    checkTerms = () => {
        this.setState({
            isTermsValid: false,
            didTermsCheck: false,
            progress: 25,
        });

        Blueberry.getCompanySettings()
            .then((resp) => {
                this.setState({
                    progress: 50,
                });
                const cloneKeys = { ...resp.jsonSettings };

                if (
                    this.props.store.user &&
                    this.props.store.user &&
                    this.props.store.user.roles &&
                    (this.props.store.user.roles.indexOf("admin") > -1 ||
                        this.props.store.user.roles.indexOf("super-admin") > -1)
                ) {
                    Object.keys(cloneKeys).map((index) => {
                        if (typeof cloneKeys[index] === "boolean")
                            cloneKeys[index] = true;
                    });
                }

                if (window._INITIAL_DATA_ && window._INITIAL_DATA_.length > 0) {
                    window._INITIAL_DATA_[window._INITIAL_DATA_.length - 2] =
                        cloneKeys;
                }

                Blueberry.checkTerms()
                    .then((resp) => {
                        if (resp && resp.valid) {
                            this.setState({
                                isTermsValid: true,
                                progress: 100,
                            });

                            this.onSuccess();
                        } else {
                            this.setState({
                                progress: 100,
                            });
                            this.delaySuccess();
                        }
                    })
                    .catch(() => {
                        this.setState({
                            progress: 100,
                        });
                        this.delaySuccess();
                    });
            })
            .catch(() => {
                this.setState({
                    progress: 100,
                });
                this.delaySuccess();
            });
    };

    delaySuccess = (success) => {
        setTimeout(() => {
            this.setState({
                isTermsValid: false,
                didTermsCheck: true,
            });
        }, 200);
    };

    logout = () => {
        const { lang } = this.props.match.params;
        Blueberry.logout()
            .then(() => {
                this.props.history.push(`/${this.getBasePath()}/account/login`);
            })
            .catch(() => {
                this.props.history.push(`/${this.getBasePath()}/account/login`);
            });
    };

    acceptTerms = () => {
        const { isTermsValid, didTermsCheck, acceptedTerms, acceptedCookies } =
            this.state;

        if (!acceptedTerms || !acceptedCookies) {
            this.setState({
                errorMessage: "Please accept both terms and conditions",
            });
            return;
        }
        this.setState({ isAcceptingTerms: true });
        Blueberry.acceptTerms({ acceptedTerms, acceptedCookies })
            .then((resp) => {
                console.log("resp", resp);
                // this.setState({ isAcceptingTerms: true });
                this.onSuccess();
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isAcceptingTerms: false });
            });
    };

    onSuccess = () => {
        const { brandSlug } = this.props.match.params;
        const { redirect_url } = this.state;
        const basePath = `/${this.getBasePath()}/`;

        var connection =
            navigator && navigator.connection
                ? navigator.connection ||
                  navigator.mozConnection ||
                  navigator.webkitConnection
                : { type: "CELL_2G" };
        var type = connection.type;

        if (
            typeof window !== "undefined" &&
            window.__REGISTERED_LOGIN_HANDLERS__ &&
            type !== "CELL_3G" &&
            type !== "CELL_2G"
        ) {
            window.__REGISTERED_LOGIN_HANDLERS__.map((index) => {
                index();
            });
        }

        setTimeout(() => {
            if (
                redirect_url != null &&
                redirect_url !== "" &&
                redirect_url.indexOf(basePath) > -1
            ) {
                this.props.history.push(
                    `${basePath}${redirect_url.split(basePath)[1]}`
                );
            } else {
                this.props.history.push(
                    brandSlug ? `${basePath}brand` : `${basePath}home-login/`
                );
            }
        }, 200);
    };

    getCustomT = (param) => {
        const { lang } = this.props.match.params;
        const langTerms = getLang(lang);

        return langTerms[param];
    };

    renderHeader = () => null;
    renderFooter = () => null;

    renderBody() {
        const {
            isTermsValid,
            errorMessage,
            isAcceptingTerms,
            didTermsCheck,
            acceptedTerms,
            acceptedCookies,
            progress,
        } = this.state;
        const { lang } = this.props.match.params;

        const title = "Checking Account - " + websiteName;

        const isLoading = !didTermsCheck;

        return (
            <Wrapper>
                <Helmet>
                    <title>{title}</title>
                    <body className="fullheight is-bg-light page-terms"></body>
                </Helmet>
                {isLoading && (
                    <LoadLayerComponent
                        progress={progress}
                        loadingText={this.getCustomT("loading")}
                    />
                )}
                {!isLoading && (
                    <BoxExtended>
                        <LogoWrapper>
                            <Logo />
                        </LogoWrapper>
                        <Header>
                            <h3>{this.getCustomT("haveUpdated")}</h3>
                            <p>{this.getCustomT("helpUpdated")}</p>
                        </Header>
                        <Center>
                            <Field>
                                <Checkbox>
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="acceptedTerms"
                                            checked={acceptedTerms}
                                            onChange={this.setValue}
                                        />
                                        <span></span>
                                        <p>
                                            {this.getCustomT(
                                                "pleaseAcceptTerms"
                                            )}{" "}
                                            <a
                                                target="_blank"
                                                href={`/${lang}/${this.getTermsSlug(
                                                    lang
                                                )}`}
                                            >
                                                {this.getCustomT(
                                                    "ternsAndConditions"
                                                )}
                                            </a>
                                        </p>
                                    </label>
                                </Checkbox>
                            </Field>
                            <Field>
                                <Checkbox>
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="acceptedCookies"
                                            checked={acceptedCookies}
                                            onChange={this.setValue}
                                        />
                                        <span></span>
                                        <p>
                                            {this.getCustomT(
                                                "pleaseAcceptCookiePolicy"
                                            )}{" "}
                                            <a
                                                target="_blank"
                                                href={`/${lang}/${this.getTermsSlug(
                                                    lang
                                                )}`}
                                            >
                                                {this.getCustomT(
                                                    "cookiePolicy"
                                                )}
                                            </a>
                                        </p>
                                    </label>
                                </Checkbox>
                            </Field>
                        </Center>
                        <Buttons isRight>
                            <Button onClick={this.logout}>
                                {this.getCustomT("logoutAndDontAccept")}
                            </Button>
                            <Button
                                onClick={this.acceptTerms}
                                primary
                                loading={isAcceptingTerms}
                            >
                                {this.getCustomT("Accept")}
                            </Button>
                        </Buttons>
                        {errorMessage && (
                            <ErrorMessage errorMessage={errorMessage} />
                        )}
                    </BoxExtended>
                )}
            </Wrapper>
        );
    }
}

export default withTracker({})(Login);
