import React from "react";
import Icons from "../Icons";
import { Control } from "../../../sdk/Form";
import { Hero, Search, Tags } from "../style";

function HeroComponent({
	langJson, setQuery, query, setFilter, filter, brandName, setBrandName, setBrandId,
	category, setCategory, collection, setCollection,
}) {
    const handleSetFilter = () => {
        setFilter("");
    };
    
    const handleSetBrand = () => {
        setBrandName("");
        setBrandId("");
    }

	const handleSetCategory = () => setCategory("");

	const handleSetCollection = () => setCollection("");

    return (
        <Hero bg="https://www.noritex.com/uplds/2024/0/22/NXBebEGiKu7x39kamntxmedia-hero.jpg">
            <span className="logo">
                <Icons icon="ntx-media" />
            </span>
            <div className="hero__container">
                <h1>{langJson.title}</h1>
                <Search>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <Control isExpanded>
                            <input
                                type="text"
                                onChange={(e) => setQuery(e.target.value)}
                                value={query}
                                placeholder={langJson.placeholder}
                            />
                            <button>
                                <Icons icon="search" />
                            </button>
                        </Control>
                    </form>
                </Search>
                <div className="tags__container">
                    <Tags>
						{ brandName && (<div className="tag" onClick={handleSetBrand}>{brandName} <Icons icon="close" /></div>)}
						{ filter && (<div className="tag" onClick={handleSetFilter}>{langJson[filter]} <Icons icon="close" /></div>)}
						{ category && (<div className="tag" onClick={handleSetCategory}>{category} <Icons icon="close" /></div>)}
						{ collection && (<div className="tag" onClick={handleSetCollection}>{collection} <Icons icon="close" /></div>)}
                    </Tags>
                </div>
            </div>
        </Hero>
    );
}

export default HeroComponent;
