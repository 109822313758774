import React from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Pagina from "../../../components/Pagina";
import { Preloader, Tabs } from "../../../sdk/Elements";
import { BlankState } from "../../../sdk/Layout";
import { MediaItem, MediaList } from "../style";
import Icons from "../Icons";
import { Button, Icon } from "../../../sdk/Button";
import DownloadButton from "./DownloadButton";

const getFileExtension = (url) => {
    if (url != null) {
        return url.split(".").pop();
    }
    return null;
};

function MediaGrid({
    medias,
    page,
    setPage,
    limit,
    handleSetMedia,
    closeModal,
    isLoading,
    langJson,
    files,
    handleSetFiles
}) {
    if (
        !isLoading &&
        medias &&
        ((medias.medias && medias.medias.length > 0) ||
            medias.product ||
            (medias.collections && medias.collections.length > 0))
    ) {
        return (
            <>
                <MediaList>
                    {medias.product &&
                    medias.product.imagesUrl &&
                    medias.product.imagesUrl.length > 0
                        ? medias.product.imagesUrl.map((index) => (
                              <MediaItem
                                  key={index.thumb}
                                  onClick={() => handleSetMedia(index)}
                              >
                                  <figure>
                                      <LazyLoadImage
                                          src={index.thumb}
                                          alt={
                                              medias.product
                                                  ? medias.product.title
                                                  : " "
                                          }
                                      />
                                  </figure>
                                <h3 className="title">
                                    {medias.product
                                        ? medias.product.title
                                        : " "}
                                </h3>
                              </MediaItem>
                          ))
                        : null}

                    {medias.collections
                        ? medias.collections.map((index) => {
                              if (
                                  index &&
                                  index.imagesUrl &&
                                  index.imagesUrl.length > 0
                              ) {
                                  return (
                                      <MediaItem
                                          key={index._id}
                                          onClick={() =>
                                              handleSetMedia(index.imagesUrl[0])
                                          }
                                      >
                                          <figure>
                                              <LazyLoadImage
                                                  src={index.imagesUrl[0].thumb}
                                                  alt={index.title}
                                              />
                                          </figure>
                                          <h3>{index.title}</h3>
                                      </MediaItem>
                                  );
                              }
                              return null;
                          })
                        : null}
                    {medias.medias.map((index) => {
                        return (
                            <MediaItem
                                key={index._id}
                            >
                                <figure>
                                    <LazyLoadImage
                                        src={
                                            index.thumb
                                                ? index.thumb
                                                : "https://www.noritex.com/uplds/2021/9/27/dSLSJGQjaoGLaWxexdoc-placeholder.jpg"
                                        }
                                        alt={index.title}
                                    />
                                </figure>
                                <div className="inner">
                                    <div className="inner__top">
                                        <h3 className="title">{index.title}</h3>
                                        <ul className="metadata">
                                            <li>{getFileExtension(index.url)}</li>
                                            {/* <li>120 descargas</li> */}
                                        </ul>
                                    </div>

                                    <div className="inner__bottom">
                                        <div className="actions">
                                            <div className={`action hide-on-mobile ${files.includes(index) ? 'is-active' : ''}`} onClick={() => handleSetFiles(index)}><Icons icon="plus"/></div>
                                            <DownloadButton media={index} langJson={langJson}/>
                                        </div>
                                    </div>
                                  </div>
                            </MediaItem>
                        );
                    })}
                </MediaList>
                <Pagina
                    pages={medias.pages}
                    currentPage={page}
                    setPage={setPage}
                    order={null}
                    limit={limit}
                    count={medias.count}
                />
            </>
        );
    }

    if (isLoading) {
        return (
            <MediaList>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
                    (index) => {
                        return (
                            <MediaItem key={`preloader-ntxmedia-${index}`}>
                                <figure>
                                    <Preloader square />
                                </figure>
                                <h3>
                                    <Preloader half />
                                </h3>
                            </MediaItem>
                        );
                    }
                )}
            </MediaList>
        );
    }

    return (
        <BlankState>
            <div>
                {/* <IconLibrary2 icon="plus-square" /> */}
                <h3>{langJson.nothingFound}</h3>
            </div>
        </BlankState>
    );
}

export default React.memo(MediaGrid);
