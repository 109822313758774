import React from "react";
import useTranslation from "../../../lang/hooks/useTranslation";
import { PaginationExtended, PaginationWrapper, InfoSection } from "./style";

const getPages = (
    pages,
    currentPage,
    setPage,
    ref,
    order,
    limit,
    { next, back }
) => {
    const map = [];
    const pagesShowned = Math.min(4, pages);

    const onSetPage = (e) => {
        e.preventDefault();
        setPage(parseInt(e.target.getAttribute("data-page")));
    };

    if (currentPage - 1 > 0) {
        const nextPage = currentPage - 1;
        map.push(
            <li key={`pagination-page-back`} ref={ref}>
                <a
                    href={`?page=${nextPage}&order=${order}&limit=${limit}`}
                    className={`pagination--item`}
                    onClick={onSetPage}
                    data-page={nextPage}
                >
                    {back}
                </a>
            </li>
        );
    }

    for (var number = 1; number <= pages; number++) {
        if (
            number === 1 ||
            number === pages ||
            (number >= currentPage - 2 && number <= currentPage + 2)
        ) {
            if (number === 1)
                map.push(
                    <li key={`pagination-page${number}`} ref={ref}>
                        <a
                            href={`?page=${number}&order=${order}&limit=${limit}`}
                            className={`pagination--item ${
                                currentPage === number ? "is-current" : ""
                            }`}
                            onClick={onSetPage}
                            data-page={number}
                        >
                            {number}
                        </a>
                    </li>
                );
            else {
                map.push(
                    <li key={`pagination-page${number}`}>
                        <a
                            href={`?page=${number}&order=${order}&limit=${limit}`}
                            className={`pagination--item ${
                                currentPage === number ? "is-current" : ""
                            }`}
                            onClick={onSetPage}
                            data-page={number}
                        >
                            {number}
                        </a>
                    </li>
                );
            }
        }

        // if the next page is not showned then show ...
        if (
            (number + 1 === pages &&
                !(number >= currentPage - 2 && number <= currentPage + 2)) ||
            (number === 1 && currentPage > 4)
        ) {
            map.push(
                <li key={`${number}-pagination-page...`}>
                    <a>...</a>
                </li>
            );
        }
    }

    if (currentPage + 1 <= pages) {
        const nextPage = currentPage + 1;
        map.push(
            <li key={`pagination-page-next`} ref={ref}>
                <a
                    href={`?page=${nextPage}&order=${order}&limit=${limit}`}
                    className={`pagination--item`}
                    onClick={onSetPage}
                    data-page={nextPage}
                >
                    {next}
                </a>
            </li>
        );
    }
    return map;
};

const Pagina = React.forwardRef(
    ({ pages, currentPage, setPage, order, limit, count }, ref) => {
        const [_t] = useTranslation();
        if (pages <= 1) return null;
        return (
            <PaginationWrapper>
                <PaginationExtended className="is-centered">
                    <ul id="pagination">
                        {getPages(
                            pages,
                            currentPage,
                            setPage,
                            ref,
                            order,
                            limit,
                            {
                                next: _t("next"),
                                back: _t("back"),
                            }
                        )}
                    </ul>
                </PaginationExtended>
                {count ? (
                    <InfoSection>
                        {_t("viwed")}{" "}
                        {count < limit * currentPage
                            ? count
                            : limit * currentPage}{" "}
                        {_t("of")} {count}
                    </InfoSection>
                ) : null}
            </PaginationWrapper>
        );
    }
);
export default Pagina;
