export default {
	supportList: [
		"Claims can be processed directly on this platform for expedited attention. Please complete all the necessary fields.",
		"All claims must be processed according to the invoiced prices. Import or other costs will not be considered.",
		"Claims submitted for damages must be supported with clear photos that denote the damages indicated. <strong> Claims without photos will not be accepted. </strong>",
		"Claims must be made within 45 days after the invoice date, for shipments from Z. L. and 60 days after the invoice date for direct shipments.",
		"Once the merchandise is received, only one claim per dispatch will be accepted. Partial claims on the same invoice are not accepted.",
		"Claims must be previously approved by Noritex before being deducted from payments. After being reviewed and approved, the claim will be registered in the system as evidence.",
		"Claims will be dealt with in a reasonable time (1 month) from the submition date of the client. Depending on the complexity of the claim, the factory will be consulted, which entails a longer response time. ",
		"Any customs process where the cargo is withheld due to any fact attributable to Noritex we must be notified immediately.",
		"Keep in mind that when receiving the containers you must verify and take photos of the container number and seals.",
		"When a situation is found in the container (loss or damage) with merchandise corresponding to our dispatch, you must suspend the unloading, close the container and immediately notify an inspector to raise a report with the due photographic record so that the claim takes place. ",
	],

	beginClaim: "Start Claim",
	productTitle: "2. Enter the images related to your claim",
	addAnother: "Add Another",
	addFiles: "Add Photos",
	sendToMyRepresentative: "Send to my representative",
	final1: "We are processing your claim.",
	final2: "Ready, your sales representative will be contacting you.",
	final3: "Go to Home",
	final4: "Manage claims",
	firstStepTitle: "1. Update your information",
	firstStepSubTitle:
		'If the information is correct, press "Next" to continue with the claim',
	dragAndDrop: "Upload 1 photo/video with all affected parts and at least 4 detailed photos from different angles of the defect",
	claimMotive: "Reason for Claim",
	claimOptions: ["Packaging Quality", "Product Quality", "Missing", "Surplus", "Incomplete Package", "Wrong Product"],
	claimOptionsDetail: [
		"Deterioration due to poor packaging.",
		"Presents manufacturing defects.",
		"Merchandise that was not shipped, but was invoiced.",
		"Merchandise that was not purchased, but was shipped.",
		"Merchandise missing from the master cash register but invoiced.",
		"Product received is not the same as the sample purchased.",
	],
	claimOptionsMap: {
		broken: "Packaging Quality",
		"poor quality": "Product Quality",
		missing: "Missing",
		surplus: "Surplus",
		bulk: "Incomplete Package",
		wrong: "Wrong Product",
	},
	claimOptionsImages: {
		broken: "required (max. 5)",
		"poor quality": "required (max. 5)",
		missing: "upload image (optional)",
		surplus: "upload image (Add at least 1 photo to support your claim)",
		bulk: "upload image (Add at least 1 photo to support your claim)",
		wrong: "upload image (Add at least 1 photo to support your claim)",
	},
	claimAlert: "Upload the same number of photos as defects reported",
	claimMissingImages: "Pending photos to upload",
	claimMissingValue: "Enter amount to claim",
	claimOverflow: "The quantity claimed cannot be greater than quantity invoiced",
	motive: "Select Reason",
	pleaseSelect:
		"Please select the brand you would like to submit a claim form:",
	beginNewClaim: "Start New Claim",
	myClaims: "My Claims",
	filterAll: "All",
	filterOpen: "Open",
	filerProcess: "In Progress",
	filterClosed: "Closed",
	filterDeclined: "Declined",
	headerClaim: "Claim",
	headerBill: "Invoice",
	headerDate: "Created Date",
	headerStatus: "Status",
	headerTotal: "Total to be Claimed",
	headerTotalClaim: "Total Claim",
	headerTotalBill: "Total Bill",
	headerTotalCreditNote: "Total Credit Note",
	headerTotalNote: "The total actual claim amount will be indicated after review by your salesperson.",
	details: "Details",
	of: "of",
	addingTitle: "Select one or more to add",
	addingPlaceholder: "Search for a product...",
	addingButton: "Add",
	noDataTitle: "No claims found",
	noData: "No claims were found in your account..",
	statusOpen: "Your claim is under review for approval.",
	statusClosed: "Your claim was successfully addressed.",
	statusInProgress: "Your claim is being attended by our team.",
	statusDeclined: "Your claim was rejected. Review the reasons.",
	claimDetails: "Claim Details",
	editClaim: "Edit Claim",
	backToMyClaims: "Back to My Claims",
	claimOptionStatus: {
		open: "OPEN",
		inProgress: "IN PROGRESS",
		closed: "APPROVED",
		declined: "DECLINED",
		requireApproved: "IN PROGRESS",
	},
	claimPrice: "Price",
	claimQuantity: "Invoiced Quantity",
	claimQuantityPrev: "Previously Claimed",
	claimCustomError: {
		"claim-previous-quantity": "Quantity greater than invoiced according to previous claims",
	},
	moreInfo: "More info",
	quantityToClaim: "Quantity to be Claimed",
	comments: "Comments",
	commentsPlaceholder: "Add comments (optional)",
	dhProduct: "Product",
	dhInvoiced: "Invoiced",
	dhClaimed: "Claimed",
	dhMotive: "Motive",
	dhPrice: "Price",
	dhTotalClaimed: "Total Claimed",
	dhTotalApproved: "Total Approved",
};
