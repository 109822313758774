import React from "react";
import withTracker from "../../blueberry/connect";
import MainTemplate from "../templates/MainTemplate";
import InternalLink from "../components/InternalLink";
import Blueberry from "../../blueberry/index";
import ErrorMessage from "../components/ErrorMessage/index";

import { Button, Buttons } from "../sdk/Button";
import { Control, Field } from "../sdk/Form";
import { Section, Heading } from "../sdk/Layout";
import { SmallContainer, InfoSection } from "./style";
class AccountInvite extends MainTemplate {
    state = {
        email: "",
        password: "",
        success: false,
        passwordError: [],
    };

    componentDidUpdate(prevProps) {
        if (this.props.store.user.id !== prevProps.store.user.id) {
            if (this.props.store.user.id) this.onSuccess();
        }
    }

    componentDidMount() {
        const self = this;

        if (this.props.store.user.id) this.onSuccess();
    }

    onSuccess = (error, response) => {
        this.props.history.push(
            `/${this.props.match.params.lang}/account/check`
        );
    };

    onSubmit = (e) => {
        e.preventDefault();
        const { email, password } = this.state;
        const self = this;
        var lowerCaseLetters = /[a-z]/g;
        var numbers = /[0-9]/g;
        if (password < 6) {
            self.setState({
                errorMessage: this._t("password8Characters"),
                success: false,
            });
            return "";
        } else if (!password.match(lowerCaseLetters)) {
            self.setState({
                errorMessage: this._t("passwordLowerCase"),
                success: false,
            });
            return "";
        }
        if (this.props.match.params.token) {
            Blueberry.acceptInvite({
                token: this.props.match.params.token,
                password,
            })
                .then((email) => {
                    // self.gotoLogin();
                    self.loginUser(email, password);
                    self.setState({
                        errorMessage: "Password has been set",
                        success: true,
                    });
                })
                .catch((error) => {
                    console.log("There was an error:" + error.reason);
                    console.log("There", error);
                    self.setState({
                        errorMessage: error.reason,
                        success: false,
                    });
                });
            return;
        }
    };

    loginUser(email, password) {
        const self = this;
        Blueberry.login({ email: email, password })
            .then((userId) => {
                this.setState({ isLoading: false });
                if (analytics)
                    analytics.track("Signed In", {
                        username: email,
                    });

                setTimeout(() => {
                    self.onSuccess(userId);
                }, 300);
            })
            .catch((error) => {
                console.log("There was an error:" + error.reason);
                console.log("There", error);
                this.setState({ errorMessage: error.reason, isLoading: false });
            });
    }

    setValue = (e) => {
        let obj = {};
        let name = e.target.name;
        let value = e.target.value;
        let passwordError = [];
        if (name === "password") {
            var lowerCaseLetters = /[a-z]/g;
            var upperCaseLetters = /[A-Z]/g;
            var numbers = /[0-9]/g;
            if (!value.match(lowerCaseLetters))
                passwordError.push(this._t("passwordLowerCase"));
            if (!value.match(upperCaseLetters))
                passwordError.push(this._t("passwordUpperCase"));
            if (!value.match(numbers))
                passwordError.push(this._t("passwordNumber"));
            if (value.length < 8)
                passwordError.push(this._t("password8Characters"));
            obj["passwordError"] = passwordError;
        }
        obj[name] = value;
        this.setState(obj);
    };

    renderBody() {
        const { email, errorMessage, password, passwordError } = this.state;
        return (
            <Section>
                <SmallContainer>
                    <div id="customer_recovery" data-account-login-recovery>
                        <form>
                            <Heading isCentered>
                                <h3>{this._t("inviteHeader")}</h3>
                            </Heading>
                            <p>{this._t("enterPassword")}</p>

                            {errorMessage && (
                                <ErrorMessage errorMessage={errorMessage} />
                            )}

                            {this.props.match.params.token ? (
                                <Field>
                                    <label htmlFor="password1">Password</label>
                                    <Control isExpanded>
                                        <input
                                            type="password"
                                            name="password"
                                            value={password}
                                            onChange={this.setValue}
                                            className={`${
                                                passwordError.length > 0
                                                    ? " is-invalid"
                                                    : ""
                                            }`}
                                            id="password1"
                                        />
                                        <div className="invalid-feedback">
                                            <ul>
                                                {passwordError.map(
                                                    (index, i) => {
                                                        return (
                                                            <li
                                                                key={
                                                                    "errors" + i
                                                                }
                                                            >
                                                                {index}
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </div>
                                    </Control>
                                </Field>
                            ) : (
                                <div className="form-field">
                                    <input
                                        className="form-field-input form-field-text"
                                        id="customer_recovery_email"
                                        name="email"
                                        type="text"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        value={email}
                                        onChange={this.setValue}
                                        style={{
                                            backgroundRepeat: "no-repeat",
                                            backgroundAttachment: "scroll",
                                            backgroundSize: "16px 18px",
                                            backgroundPosition: "98% 50%",
                                        }}
                                    />
                                    <label
                                        className="form-field-title"
                                        htmlFor="customer_recovery_email"
                                    >
                                        Email
                                    </label>
                                </div>
                            )}
                            <div className="form-action-row">
                                <Buttons hasLink>
                                    <Button
                                        onClick={this.onSubmit}
                                        type="submit"
                                        primary
                                    >
                                        Submit
                                    </Button>
                                    <InternalLink
                                        type="button"
                                        to="/account/login"
                                    >
                                        <Button>Cancel</Button>
                                    </InternalLink>
                                </Buttons>
                            </div>
                        </form>
                    </div>
                </SmallContainer>
            </Section>
        );
    }
}
export default withTracker({})(AccountInvite);
