/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import withTracker from "../../../blueberry/connect";
import MainTemplate from "../../templates/MainTemplate";
import { Container, Section, Columns, Column } from "../../sdk/Layout";
import Hero from "./components/Hero";
import FilterBrands from "./components/FilterBrands";
import MediaGrid from "./components/MediaGrid";
import Icons from "./Icons";
import { Filters, IconGroup, ColumnExtended, ToggleFilterMobile } from "./style";

import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";
import useQueryString from "../../hooks/useQueryString";
import Blueberry from "../../../blueberry";
import SEOFieldsHelmet, { websiteName } from "../../utils/SEOFieldsHelmet";
import MultipleDownloadBar from "./components/MultipleDownloadBar";


function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

function ContainerMedia({ langJson, setWindowTop }) {
    const [query, setQuery] = useQueryString("query", "");
    const [filter, setFilter] = useQueryString("filter", "");
    const [limit, setLimit] = useQueryString("limit", 32, "int");
    const [page, setPage] = useQueryString("page", 1, "int");
    const [brandId, setBrandId] = useQueryString("brandId", "");
    const [brandName, setBrandName] = useQueryString("");
	const [category, setCategory] = useQueryString("category", "");
	const [collection, setCollection] = useQueryString("collection", "");

	const handleSetFilter = (e) => {
		e.preventDefault();
		setFilter(e.target.getAttribute("data-value"));
	};

	const handleSetCategory = (e) => {
		e.preventDefault();
		setCategory(e.target.getAttribute("data-value"));
	};

	const handleSetCollection = (e) => {
		e.preventDefault();
		setCollection(e.target.getAttribute("data-value"));
	};

    const [medias, setMedias] = React.useState({
        medias: [],
        product: [],
        collections: [],
        count: 0,
        pages: 0,
        brands: [],
		cats: [],
		collectionsMedia: [],
    });

    const [isLoading, setIsLoading] = React.useState(false);

    const handleSetMedia = (media) => {
        setCurrentMedia(media);
        setIsModalVisitble(true);
    };

    React.useEffect(() => {
        setPage(1);
    }, [brandId, filter, query]);

    React.useEffect(() => {
        setWindowTop();
        setIsLoading(true);
        Blueberry.getMedias({
            limit,
            page,
            queryString: query,
            brandId,
            filter,
			categories: category,
			collectionsMedia: collection,
        })
            .then((resp) => {
                setIsLoading(false);
                if (resp) setMedias(resp);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }, [query, filter, page, limit, brandId, category, collection]);


    const [showFilters, setShowFilters] = React.useState(true);

    const handleToggleFilters = () => {
        setShowFilters((prevState) => !prevState);
    };

    const [showFilterMobile, setShowFilterMobile] = React.useState(false);
    const handleToggleFiltersMobile = () => {
        setShowFilterMobile((prevState) => !prevState);
    }

    const storedFiles = JSON.parse(localStorage.getItem('files')) || [];
    const [files, setFiles] = React.useState(storedFiles);

    useEffect(() => {
        localStorage.setItem('files', JSON.stringify(files));
      }, [files]);

    const handleSetFiles = (file) => {
        if (files.includes(file)) {
            setFiles((prevState) => prevState.filter((item) => item !== file));
            return;
        }
        
        setFiles((prevState) => [...prevState, file]);
    }

    const handleClearFiles = () => {
        setFiles([]);
    };

	return (
		<>
			<Hero
                langJson={langJson}
                query={query}
                setQuery={setQuery}
                filter={filter}
                setFilter={setFilter}
                brandName={brandName}
                setBrandName={setBrandName}
                setBrandId={setBrandId}
				category={category}
				setCategory={setCategory}
				collection={collection}
				setCollection={setCollection}
			/>

			<Section>
				<Container isFluid>
					<Columns>
						<ColumnExtended isNarrow>
							<div className={`column__toggle ${!showFilters ? "is-filters-hidden" : ""}`} onClick={handleToggleFilters}><Icons icon={showFilters ? "chevron-double-left" : "chevron-double-right"} /></div>
							<ToggleFilterMobile onClick={handleToggleFiltersMobile} className={showFilterMobile ? 'is-open' : ''}>{langJson.filtrar} <Icons icon="menu-down"/></ToggleFilterMobile>
							{showFilters && (
								<Filters className={showFilterMobile ? "is-open" : ""}>
									<div>
										<div className="filter__label">{langJson.marcas}</div>
										<FilterBrands
											brands={medias.brands}
											setBrandId={setBrandId}
											setBrandName={setBrandName}
											brandId={brandId}
										/>
									</div>
									<div>
										<div className="filter__label">{langJson.recursos}</div>
										<ul>
											<li className={filter === "images" ? "is-active" : ""}>
												<a
													href="##"
													data-value="images"
													onClick={handleSetFilter}
												>
													<Icons icon="image"/>
													{langJson.images}
												</a>
											</li>
											<li className={filter === "videos" ? "is-active" : ""}>
												<a
													href="##"
													data-value="videos"
													onClick={handleSetFilter}
												>
													<Icons icon="video" />
													{langJson.videos}
												</a>
											</li>
											<li className={filter === "blogs" ? "is-active" : ""}>
												<a href="##" data-value="blogs" onClick={handleSetFilter}>
													<Icons icon="note-text" />
													{langJson.blogs}
												</a>
											</li>
											<li className={filter === "branding" ? "is-active" : ""}>
												<a
													href="##"
													data-value="branding"
													onClick={handleSetFilter}
												>
													<Icons icon="brush" />
													{langJson.branding}
												</a>
											</li>
											<li className={filter === "catalogs" ? "is-active" : ""}>
												<a
													href="##"
													data-value="catalogs"
													onClick={handleSetFilter}
												>
													<Icons icon="file-pdf-box" />
													{langJson.catalogs}
												</a>
											</li>
										</ul>
									</div>
									{/* <div>
										<div className="filter__label">Orientación</div>
										<IconGroup>
											<div><Icons icon="panorama-horizontal" /></div>
											<div><Icons icon="panorama-vertical" /></div>
											<div><Icons icon="square-rounded" /></div>
										</IconGroup>
									</div> */}
									{
										(medias?.cats?.length > 0) && (
											<div>
												<div className="filter__label">{langJson.categories}</div>
												<ul className="lower">
													{
														(medias?.cats?.length) && medias?.cats.map((cat) => (
															<li key={`CAT-${cat}`} className={category === cat ? "is-active" : ""}>
																<a href="##" data-value={cat} onClick={handleSetCategory}>
																	{cat.toLowerCase()}
																</a>
															</li>
														))
													}
												</ul>
											</div>
										)
									}
									{
										(medias?.collectionsMedia?.length > 0 && brandName) && (
											<div>
												<div className="filter__label">
													{brandName === "Merletto" ? langJson.campaigns : langJson.collections}
												</div>
												<ul className="lower">
													{
														medias.collectionsMedia.map((coll) => (
															<li key={`COLL-${coll}`} className={collection === coll ? "is-active" : ""}>
																<a href="##" data-value={coll} onClick={handleSetCollection}>
																	{coll}
																</a>
															</li>
														))
													}
												</ul>
											</div>
										)
									}
								</Filters>
							)}
						</ColumnExtended>
						<Column>
							<MediaGrid
								medias={medias}
								langJson={langJson}
								limit={limit}
								isLoading={isLoading}
								page={page}
								setPage={setPage}
								handleSetMedia={handleSetMedia}
								handleSetFiles={handleSetFiles}
								files={files}
							/>
						</Column>
					</Columns>
				</Container>
			</Section>
            
            {files.length > 0 && (
                <MultipleDownloadBar 
                    files={files} 
                    handleSetFiles={handleSetFiles} 
                    langJson={langJson} 
                    handleClearFiles={handleClearFiles}
                />
            )}
		</>
	);
}

class NTXMedia extends MainTemplate {
    renderFooter = () => null;

    renderBody() {
        const { lang, brandSlug } = this.props.match.params;
        const langJson = getLang(lang);

        return (
            <>
                <SEOFieldsHelmet
                    bodyClassName="ntx-media"
                    defaultTitle={websiteName + " Media"}
                    defaultDescription={websiteName + " Media"}
                    lang={lang}
                />
                <ContainerMedia
                    langJson={langJson}
                    setWindowTop={this.setWindowTop}
                />
            </>
        );
    }
}

export default withTracker()(NTXMedia);
