export default {
	supportList: [
		"As reclamações podem ser processadas directamente nesta plataforma para uma atenção expedita. Por favor, preencha todos os campos necessários.",
		"Todas as reclamações devem ser processadas de acordo com os preços facturados. Os custos de importação ou outros não serão considerados.",
		"As reclamações apresentadas por danos devem ser apoiadas com fotografias claras que denotam os danos indicados. <strong>Reclamações sem fotografias não serão aceites</strong>.",
		"As reclamações devem ser feitas no prazo de 45 dias após a data da factura, para envios a partir de Z. L. e 60 dias após a data da factura, para envios directos.",
		"Uma vez recebida a mercadoria, só será aceite uma reclamação por despacho. Não são aceites reclamações parciais sobre a mesma factura.",
		"As reclamações devem ser previamente aprovadas pela Noritex antes de serem deduzidas dos pagamentos. Após ser revista e aprovada, a reivindicação será registada no sistema como prova.",
		"As reclamações serão tratadas num prazo razoável (1 mês) a partir da data de submissão do cliente. Dependendo da complexidade da reclamação, a fábrica será consultada, o que implica um tempo de resposta mais longo.",
		"Qualquer processo aduaneiro em que a carga seja retida devido a qualquer facto imputável à Noritex, devemos ser imediatamente notificados.",
		"Tenha em mente que ao receber os recipientes deve verificar e tirar fotografias do número do recipiente e dos selos.",
		"Quando uma situação é encontrada no contentor (perda ou dano) com mercadoria correspondente ao nosso despacho, deve suspender a descarga, fechar o contentor e notificar imediatamente um inspector para levantar um relatório com o devido registo fotográfico para que a reclamação tenha lugar.",
	],
	beginClaim: "Iniciar reclamação",
	productTitle:
		"2. Introduza as imagens relacionadas com a sua reivindicação.",
	addAnother: "Acrescentar outra",
	addFiles: "Adicionar Fotos",
	sendToMyRepresentative: "Enviar ao meu representante",
	final1: "Estamos a processar o seu pedido.",
	final2: "Ready, o seu representante de vendas entrará em contacto consigo.",
	final3: "Ir para Início",
	final4: "Gerir reclamações",
	firstStepTitle: "1. Atualize suas informações",
	firstStepSubTitle:
		'Se a informação estiver correcta, prima "Seguinte" para continuar com a reclamação',
	dragAndDrop:
		"Carregue 1 foto/vídeo com todas as partes afetadas e pelo menos 4 fotos detalhadas de diferentes ângulos do defeito",
	claimMotive: "Motivo da reclamação",
	claimOptions: ["Qualidade da Embalagem", "Qualidade do Produto", "Falta", "Excedente", "Pacote Incompleto", "Produto Errado"],
	claimOptionsDetail: [
		"Deterioração devido à má embalagem.",
		"Apresenta defeitos de fabrico.",
		"Mercadoria que não foi enviada, mas que foi facturada.",
		"Mercadoria que não foi comprada, mas que foi enviada.",
		"Mercadorias ausentes do mestre até mas faturadas.",
		"O produto recebido não é o mesmo que a amostra comprada.",
	],
	claimOptionsMap: {
		broken: "Qualidade da Embalagem",
		"poor quality": "Qualidade do produto",
		missing: "Falta",
		surplus: "Excedente",
		bulk: "Pacote Incompleto",
		wrong: "Produto Errado",
	},
	claimOptionsImages: {
		broken: "necessário (max. 5)",
		"poor quality": "necessário (max. 5)",
		missing: "carregar imagem (opcional)",
		surplus: "carregar imagem (favor adicionar pelo menos 1 foto)",
		bulk: "carregar imagem (favor adicionar pelo menos 1 foto)",
		wrong: "carregar imagem (favor adicionar pelo menos 1 foto)",
	},
	claimAlert: "Carregar o mesmo número de fotos que os defeitos relatados",
	claimMissingImages: "Fotos a serem carregadas",
	claimMissingValue: "Entrar o valor a ser reclamado",
	claimOverflow: "A quantidade reivindicada não pode ser maior do que a quantidade faturada",
	motive: "Seleccionar Motivo",
	pleaseSelect:
		"Por favor, seleccione a marca que gostaria de apresentar um formulário de reclamação:",
	beginNewClaim: "Iniciar Nova Reclamação",
	myClaims: "As minhas reclamações",
	filterAll: "Todos",
	filterOpen: "Aberto",
	filerProcess: "Em curso",
	filterClosed: "Fechado",
	filterDeclined: "Declinado",
	headerClaim: "Reclamação",
	headerBill: "Factura",
	headerDate: "Data de criação",
	headerStatus: "Estado",
	headerTotal: "Total a ser Reclamado",
	headerTotalClaim: "Crédito Total",
	headerTotalBill: "Total da Fatura",
	headerTotalCreditNote: "Nota de Crédito Total",
	headerTotalNote: "O total real da reivindicação será indicado após análise por seu vendedor.",
	details: "Detalhes",
	of: "de",
	addingTitle: "Seleccione um ou mais para adicionar",
	addingPlaceholder: "Procura de um produto...",
	addingButton: "Adicione",
	noDataTitle: "Nenhuma reivindicação encontrada",
	noData: "Não foram encontradas reivindicações na pesquisa actual...",
	statusOpen: "O seu pedido está a ser revisto para aprovação.",
	statusClosed: "A sua reclamação foi tratada com sucesso.",
	statusInProgress: "A sua queixa está a ser tratada pela nossa equipa.",
	statusDeclined: "O seu pedido foi rejeitado. Verificar as razões.",
	claimDetails: "Detalhes da Reclamação",
	editClaim: "Editar Reclamação",
	backToMyClaims: "Voltar às Minhas Reivindicações",
	claimOptionStatus: {
		open: "ABERTO",
		inProgress: "EM PROGRESSO",
		closed: "APROVADO",
		declined: "DECLINADO",
		requireApproved: "EM PROGRESSO",
	},
	claimPrice: "Preço",
	claimQuantity: "Quantidade faturada",
	claimQuantityPrev: "Anteriormente Reclamado",
	claimCustomError: {
		"claim-previous-quantity": "Quantidade maior do que a faturada de acordo com reclamações anteriores",
	},
	moreInfo: "Mais info",
	quantityToClaim: "Quantia a ser reclamada",
	comments: "Comentários",
	commentsPlaceholder: "Adicionar comentários (opcional)",
	dhProduct: "Produto",
	dhInvoiced: "Facturação",
	dhClaimed: "Reclamado",
	dhMotive: "Motivo",
	dhPrice: "Preço",
	dhTotalClaimed: "Total Reclamado",
	dhTotalApproved: "Montante Aprovado",
};
