export default {
    successRequestingLink:
        "Sucesso ao solicitar o link, você deverá receber o link para seu e-mail.",
    errorRequestingLink: "Ocorreu um erro ao solicitar o seu link",
    linkText:
        "Se você não se lembra da sua senha, deseja que lhe enviemos um novo link de entrada?",

    notNecesary: "Não é necessário",
    yes: "Sim",
    correoInvalido: "Email Inválido",

    correoInvalido: "Email inválido",
    cancelar: "Cancelar",
    linkDeEntrada: "Solicitar link de entrada",
    linkDeEntradaContrasena: "Recuperar senha",

    haveUpdated: "Atualizamos as políticas da empresa",
    helpUpdated: "Para continuar aceite as novas condições",
    pleaseAcceptTerms: "Aceito os termos e condições",
    pleaseAcceptCookiePolicy: "Aceito as políticas de cookies",
    ternsAndConditions: "Termos e condições",
    cookiePolicy: "Política de Cookies",
    logoutAndDontAccept: "Saia e não use a plataforma",
    Accept: "Aceitar",
    loading: "Carregando",
    newClient: "Se você é um novo cliente",
    registerNow: "Inscreva-se para comprar",
    requestLoginLink: "solicitar um link de acesso por e-mail",
    ifYouPrefer: "Se preferir, você pode",
};
