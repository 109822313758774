/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Button } from "../sdk/Button";
import {
	Container, Section, Box, Columns, Column, Title, Spacer,
} from "../sdk/Layout";
import { Tag, Message, Modal } from "../sdk/Elements";
import Icons from "../components/Icons2";
import NoImage from "../components/NoImage";
import {
	Header, ClaimInfo, SectionHeader, ProductRow,
} from "./style";
import { getDate, numberWithCommas, isFileTypeofVideo } from "../utils";

const ModalExtended = styled(Modal)`
  z-index: 999;
`;

const ImageContainer = styled.div`
    text-align: center;
`;

const ImagePreview = styled.img`
    max-width: 100%;
    height: auto;
    max-height: 600px;
`;

const GalleryCaption = styled.h4`
    font-size: 10px;
    text-transform: uppercase;
    margin-top: 20px;
`;

const Note = styled.p`
	font-size: 12px;
	color: ${(props) => props.theme.gray};
	text-align: right;
`;

const SalesrepComments = styled(Message)`
	margin-top: 10px;
	font-size: 12px;
	strong {
		font-weight: 600;
	}
`;

const VideoIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	svg {
		width: 75%;
		height: 75%;
	}
`;

const VideoPreview = styled.video`
	width: 100%;
	height: auto;
	max-height: 500px;
`;

const getPropStatus = (status) => {
	if (status === "closed") return { success: true };
	if (status === "inProgress") return { alert: true };
	if (status === "declined") return { error: true };
	if (status === "requireApproved") return { alert: true };

	return { info: true };
};

const getPropMotives = (desc, lang) => {
	let motives = "";
	desc.map((d) => {
		motives += d.checked ? `${lang[d.name]},` : "";
		return motives;
	});
	return motives.slice(0, -1);
};

const getPropTotalClaimed = (issue) => {
	const total = parseFloat(issue.price) * parseFloat(issue.quantity);
	return `$${numberWithCommas(total)}`;
};

const getPropTotalApproved = (issue) => {
	const { description } = issue;

	const total = description.reduce((acc, curr) => {
		if (curr.checked) return curr.total + acc;

		return acc;
	}, 0);

	return `$${numberWithCommas(total)}`;
};

const getTotalClaim = (row, type) => {
	if (type === "CR") {
		const total = row.claimTotal || 0;
		return `$${numberWithCommas(total)}`;
	}

	let sumCredit = 0;
	row.issues.forEach((issue) => {
		sumCredit += issue.quantity * issue.price;
	});

	return `$${numberWithCommas(sumCredit)}`;
};

const getTotalBill = (row) => {
	let total = 0;
	const bill = row.billInformation;
	if (bill && bill.length > 0) {
		total = bill[0].Monto ?? 0;
	}

	return total ? `$${numberWithCommas(total)}` : "";
};

function DetailRow({
	issue, lang, showModalImage,
	claimStatus,
}) {
	const [gallery, setGallery] = useState(false);

	const handleGallery = () => {
		setGallery(!gallery);
	};

	const handleClickImage = (e, image) => {
		e.preventDefault();
		showModalImage(image);
	};

	return (
		<ProductRow>
			<div className="content">
				<Columns>
					<Column>
						<div className="info">
							<div className="item has-image" style={{ width: 200 }}>
								<div className="image">
									{ issue.thumb
										? <img src={issue.thumb} alt="" />
										: <NoImage width={28} height={28} /> }
								</div>
								<h4>{ lang.dhProduct }</h4>
								<div>
									<p>{ issue.title }</p>
								</div>
							</div>
							<div className="item" style={{ width: 90 }}>
								<h4>Ref</h4>
								<div><p>{ issue.sku }</p></div>
							</div>
							<div className="item">
								<h4>{ lang.dhInvoiced }</h4>
								<div>
									<p>
										{ issue.invoiced }
										&nbsp;items
									</p>
								</div>
							</div>
							<div className="item">
								<h4>{ lang.dhClaimed }</h4>
								<div>
									<p>
										{ issue.quantity }
										&nbsp;items
									</p>
								</div>
							</div>
							<div className="item">
								<h4>{ lang.dhPrice }</h4>
								<div>
									<p>
										$
										{ numberWithCommas(parseFloat(issue.price)) }
									</p>
								</div>
							</div>
							{
								claimStatus === "closed" ? (
									<>
										<div className="item">
											<h4>{ lang.dhTotalClaimed }</h4>
											<div>
												<p>
													{ getPropTotalClaimed(issue) }
												</p>
											</div>
										</div>
										<div className="item">
											<h4>{ lang.dhTotalApproved }</h4>
											<div>
												<p>
													{ getPropTotalApproved(issue) }
												</p>
											</div>
										</div>
									</>
								) : null
							}
							<div className="item">
								<h4>{ lang.dhMotive }</h4>
								<div>
									<p>
										{ getPropMotives(issue.description, lang.claimOptionsMap) }
									</p>
								</div>
							</div>
						</div>
					</Column>
					<Column isNarrow>
						<a href="##" className="gallery-trigger" onClick={handleGallery}>
							{
								issue.images.map((image, i) => {
									if (i < 3) {
										return (
											<div className="item" key={`${issue.sku}-${image}`}>
												{
													!isFileTypeofVideo(image) ? (
														<img src={image} alt="" />
													) : (
														<VideoIconWrapper>
															<Icons icon="video-solid" />
														</VideoIconWrapper>
													)
												}
											</div>
										);
									}
									return null;
								})
							}
							{
								issue.images.length > 0
									? (
										<div className="item indicator">
											<Icons icon="chevron-down" />
										</div>
									)
									: null
							}
						</a>
					</Column>
				</Columns>
				{
					issue.description.map((desc) => (
						(desc.checked && desc.percentageComments)
							? (
								<SalesrepComments
									alert={desc.percentage > 0}
									error={!desc.percentage}
								>
									<strong>{lang.claimOptionsMap[desc.name]}</strong>
									:&nbsp;
									{ desc.percentageComments }
								</SalesrepComments>
							)
							: null
					))
				}
			</div>
			{
				gallery
					? (
						<div>
							{
								issue.description.map((desc) => (
									(desc.checked && desc.images.length > 0)
										? (
											<div key={`gallery-${issue.sku}-${desc.name}`}>
												<GalleryCaption>{lang.claimOptionsMap[desc.name]}</GalleryCaption>
												<div className="gallery" style={{ padding: "10px 0" }}>
													{
														desc.images.map((image) => (
															<div className="item" key={`${issue.sku}-${image}`}>
																<a href="##" onClick={(e) => handleClickImage(e, image)}>
																	{
																		!isFileTypeofVideo(image) ? (
																			<img src={image} alt="" />
																		) : (
																			<VideoIconWrapper>
																				<Icons icon="video-solid" />
																			</VideoIconWrapper>
																		)
																	}
																</a>
															</div>
														))
													}
												</div>
											</div>
										) : null
								))
							}
						</div>
					) : null
			}
		</ProductRow>
	);
}

function ClaimDetails({
	match, history, detail, lang, goBack,
}) {
	const [isModalVisible, setModalVisible] = React.useState(false);
	const [modalImage, setModalImage] = React.useState(null);

	const goToEdit = () => {
		const { lang: lg, brandSlug } = match.params;
		const route = `/${lg}/${brandSlug ? `${brandSlug}/` : ""}claim?companyId=${detail.companyId}&claimId=${detail._id}&order=${detail.orderNumber}`;
		history.push(route);
	};

	const showModalImage = (image) => {
		setModalVisible(true);
		setModalImage(image);
	};

	if (!detail?._id) return null;

	return (
		<>
			<Helmet>
				<title>{lang.myClaims}</title>
			</Helmet>

			<Container>

				<Section>

					<Header>
						<Title size="4">{lang.myClaims}</Title>
					</Header>

					<Spacer height="20" />

					<div>
						<Button small onClick={goBack}>
							<Icons icon="chevron-left" />
							<span>{lang.backToMyClaims}</span>
						</Button>
					</div>
					<Spacer />
					<Box>
						<Columns>
							<Column>
								<Title
									as="h3"
									size="5"
									marginBottom="10"
								>
									{lang.headerClaim}
									&nbsp;#
									{ detail._id.slice(-5).toUpperCase() }
								</Title>
								<Tag {...getPropStatus(detail.claimStatus)}>
									{lang.claimOptionStatus[detail.claimStatus]}
								</Tag>
							</Column>
							<Column isNarrow>
								<ClaimInfo>
									<div className="item">
										<h4>{lang.headerBill}</h4>
										<div><p>{detail.orderNumber.toUpperCase()}</p></div>
									</div>
									<div className="item">
										<h4>{lang.headerDate}</h4>
										<div><p>{getDate(detail.createdAt)}</p></div>
									</div>
									<div className="item">
										<h4>{lang.headerTotalBill}</h4>
										<div><p>{getTotalBill(detail)}</p></div>
									</div>
									<div className="item">
										<h4>
											{ detail.claimStatus !== "closed" && detail.claimStatus !== "declined"
												? `*${lang.headerTotal}`
												: lang.headerTotal }
										</h4>
										<div><p>{getTotalClaim(detail)}</p></div>
									</div>
									{
										detail.claimStatus === "closed" || detail.claimStatus === "declined" ? (
											<div className="item">
												<h4>{`${lang.headerTotalCreditNote}`}</h4>
												<div><p>{getTotalClaim(detail, "CR")}</p></div>
											</div>
										) : null
									}
								</ClaimInfo>
							</Column>
						</Columns>
						{
							detail.claimStatus !== "closed" && detail.claimStatus !== "declined" ? (
								<Note>{`*${lang.headerTotalNote}`}</Note>
							) : null
						}
					</Box>

					<Spacer height="20" />

					{ detail.claimStatus === "open" ? <Message className="has-text-centered" info>{lang.statusOpen}</Message> : null }
					{ detail.claimStatus === "closed" ? <Message className="has-text-centered" success>{lang.statusClosed}</Message> : null }
					{ detail.claimStatus === "inProgress" ? <Message className="has-text-centered" alert>{lang.statusInProgress}</Message> : null }
					{ detail.claimStatus === "declined" ? <Message className="has-text-centered" error>{`${lang.statusDeclined}.. ${detail.declinedMessage}`}</Message> : null }

					<Spacer height="20" />

					<SectionHeader>
						<div>
							<Title as="h3" size="6" marginBottom="0">{lang.claimDetails}</Title>
						</div>
						<div>
							{
								(detail.claimStatus === "open")
									? (
										<Button small onClick={goToEdit}>
											<Icons icon="pencil" />
											<span>{lang.editClaim}</span>
										</Button>
									) : null
							}
						</div>
					</SectionHeader>

					{
						detail.issues.map(
							(issue) => (
								<DetailRow
									key={issue.sku}
									issue={issue}
									lang={lang}
									showModalImage={showModalImage}
									claimStatus={detail.claimStatus}
								/>
							),
						)
					}

					{
						isModalVisible
							? (
								<ModalExtended className="is-active">
									<button type="button" onClick={() => setModalVisible(false)}>&nbsp;</button>
									<div>
										<Box>
											<ImageContainer>
												{
													modalImage && !isFileTypeofVideo(modalImage) ? (
														<ImagePreview src={modalImage} alt="" />
													) : null
												}
												{
													modalImage && isFileTypeofVideo(modalImage) ? (
														<VideoPreview controls>
															<source src={modalImage} />
														</VideoPreview>
													) : null
												}
											</ImageContainer>
										</Box>
									</div>
									<span />
								</ModalExtended>
							) : null
					}

				</Section>

			</Container>
		</>
	);
}

export default ClaimDetails;
