export default {
	title: "Noritex - Ayuda de Reclamos",
	description: "Ayuda de Reclamos Noritex",
	header: "Ayúdenos a mejorar",
	subheader: "Su retroalimentación es importante",
	text1: "Los reclamos deben generarse únicamente",
	text2: "desde la página web, en la sección",
	text3: "“Mis Reclamos”",
	benefits: "Beneficios de esta modalidad",
	benefits1: "Reducción de tiempo de espera",
	benefits2: "Accesibilidad al estatus de su reclamo",
	benefits3: "Gestión rápida de incidentes",
	tools: "Herramientas de Apoyo",
	tools1: "Política de Reclamos",
	tools2: "Video Tutorial",
	tools3: "Manual",
};
