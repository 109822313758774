export default {
	claimFormDes: `
		<p>Si cometemos un error, lo arreglaremos. Si no está satisfecho con una compra, o si un producto se dañó en tránsito, queremos trabajar con usted para resolver el problema. Complete un formulario de reclamo para comenzar el proceso.</p>
		<p>Todos los reclamos deben hacerse dentro de los 10 días posteriores a la recepción de la mercancía. No se aceptarán reclamaciones después de este tiempo. Una vez que se aceptan los productos recibido el despacho la mercancía averiada es responsabilidad del cliente. Inspeccione todos los paquetes e informe sobre los daños visibles al transportista. Noritex no aceptará ningún reclamo de flete 5 días después de la recepción de la mercancía. Antes de presentar una reclamación, asegúrese de contar, abrir e inspeccionar todas las casillas para evitar múltiples reclamaciones. Esto ayudará a acelerar su proceso de reclamo.</p>

	`,
	supportList: [
		"Los reclamos podrán procesarse directamente en esta plataforma para una atención expedita. Por favor complete todos los campos necesarios.",
		"Toda reclamación ha de procesarse conforme a los precios facturados. No han de considerarse los gastos de importación u otros.",
		"Los reclamos presentados por averías deben ser sustentados con fotos claras que denoten los daños señalados. <strong>Reclamos sin fotos no proceden.</strong>",
		"Los reclamos deben efectuarse dentro de los 45 días posteriores a la fecha de factura, para despachos desde Z. L.  y 60 dias posteriores a la fecha de factura para embarques directos.",
		"Una vez recibida la mercancía, solo se aceptara un reclamo por despacho. No se aceptan reclamos parciales sobre una misma factura.",
		"Los reclamos deben ser previamente aprobados por Noritex antes de ser descontados de los pagos. Luego de revisado y aprobado, el reclamo quedará registrado en el sistema como constancia.",
		"Los reclamos serán atendidos en un tiempo prudencial de aprox. un (1) mes a partir de la presentación o solicitud del cliente. Dependiendo de la complejidad del reclamo, se deberá consultar a la fábrica involucrada, lo cual conlleva un mayor tiempo de respuesta.",
		"Cualquier proceso aduanero donde se retenga la carga por algún hecho imputable a Noritex debemos ser notificados de inmediato; al Gerente de Unidad y Servicio al Cliente.",
		"Tener presente que al recibir los contenedores debe verificar y tomar fotos del número de contenedor y sellos.",
		"Cuando se encuentre alguna situación en el contenedor (pérdida o daño) con mercancía correspondiente a nuestro despacho, debe suspender la descarga, cerrar el contenedor y notificar (contratar) de inmediato a un Inspector de Averías para que levante informe con el debido registro fotográfico de manera que el reclamo tenga lugar.",
	],

	beginClaim: "Iniciar reclamo",
	productTitle: "2. Ingrese las imágenes relacionadas a su reclamo.",
	addAnother: "Agregar otro",
	addFiles: "Agregar Fotos",
	sendToMyRepresentative: "Enviar a mi vendedor",
	final1: "Estamos procesando tu reclamo.",
	final2: " Pronto tu representante de ventas se pondrá en contacto contigo.",
	final3: "Ir al Home",
	final4: "Gestionar reclamos",
	firstStepTitle: "1. Actualice su información",
	firstStepSubTitle:
		'Si la información es correcta, presione "Siguiente" para continuar con el reclamo',
	dragAndDrop:
		"Cargar 1 foto/video con todas las piezas afectadas y al menos 4 fotos detalladas desde distintos ángulos del defecto",
	claimMotive: "Motivo del Reclamo",
	claimOptions: ["Calidad del Empaque", "Calidad del Producto", "Faltante", "Sobrantes", "Bulto Incompleto", "Producto Errado"],
	claimOptionsDetail: [
		"Deterioro por mal empaque.",
		"Presenta defectos de fabricación.",
		"Mercancía que no se despachó, pero fue facturada.",
		"Mercancía que no se compró, pero se despachó.",
		"Mercancía faltante en la caja master pero que se facturó.",
		"El producto recibido no es el mismo que la muestra comprada.",
	],
	claimOptionsMap: {
		broken: "Calidad del Empaque",
		"poor quality": "Calidad del Producto",
		missing: "Faltante",
		surplus: "Sobrante",
		bulk: "Bulto Incompleto",
		wrong: "Producto Errado",
	},
	claimOptionsImages: {
		broken: "requeridas (máx. 5)",
		"poor quality": "requeridas (máx. 5)",
		missing: "foto opcional",
		surplus: "agregue al menos 1 foto que sustente su reclamo",
		bulk: "agregue al menos 1 foto que sustente su reclamo",
		wrong: "agregue al menos 1 foto que sustente su reclamo",
	},
	claimAlert: "Subir la misma cantidad de fotos, que de defectos reportados",
	claimMissingImages: "Fotos pendientes por subir",
	claimMissingValue: "Coloque cantidad a reclamar",
	claimOverflow: "Piezas reclamadas mayor a las piezas facturadas para este producto",
	motive: "Seleccione Motivo",
	pleaseSelect:
		"Seleccione la marca que le gustaría enviar un formulario de reclamación:",
	beginNewClaim: "Iniciar Nuevo Reclamo",
	myClaims: "Mis Reclamos",
	filterAll: "Todos",
	filterOpen: "Abiertos",
	filerProcess: "En Progreso",
	filterClosed: "Cerrados",
	filterDeclined: "Rechazados",
	headerClaim: "Reclamo",
	headerBill: "Factura",
	headerDate: "Fecha Creación",
	headerStatus: "Status",
	headerTotal: "Total a Reclamar",
	headerTotalClaim: "Total Reclamo",
	headerTotalBill: "Total Factura",
	headerTotalCreditNote: "Total Nota Crédito",
	headerTotalNote: "El total real del reclamo se indicará luego de ser revisado por su vendedor",
	details: "Detalles",
	of: "de",
	addingTitle: "Seleccione uno o varios para agregar",
	addingPlaceholder: "Buscar un producto...",
	addingButton: "Agregar",
	noDataTitle: "No tienes ningun reclamo",
	noData: "No se encontraron reclamos en la búsqueda actual...",
	statusOpen: "Su reclamo se encuentra en revisión para aprobación.",
	statusClosed: "Su reclamo fue atendido con éxito.",
	statusInProgress: "Su reclamo está siendo atendido por nuestro equipo.",
	statusDeclined: "Su reclamo fue rechazado. Revise los motivos.",
	claimDetails: "Detalles del Reclamo",
	editClaim: "Editar Reclamo",
	backToMyClaims: "Volver a Mis Reclamos",
	claimOptionStatus: {
		open: "ABIERTO",
		inProgress: "EN PROGRESO",
		closed: "APROBADO",
		declined: "RECHAZADO",
		requireApproved: "EN PROGRESO",
	},
	claimPrice: "Precio",
	claimQuantity: "Cantidad Facturada",
	claimQuantityPrev: "Reclamado Previamente",
	claimCustomError: {
		"claim-previous-quantity": "Cantidad mayor a la facturada con relación a reclamos previos de esta factura",
	},
	moreInfo: "Más info",
	quantityToClaim: "Cantidad a Reclamar",
	comments: "Comentarios",
	commentsPlaceholder: "Añadir comentarios (opcional)",
	dhProduct: "Producto",
	dhInvoiced: "Facturado",
	dhClaimed: "Reclamado",
	dhMotive: "Motivo",
	dhPrice: "Precio",
	dhTotalClaimed: "Total Reclamado",
	dhTotalApproved: "Monto Aprobado",
};
