export default {
    successRequestingLink:
        "Solicitud de enlace exitosa. Recibiras el enlance a tu correo electronico.",
    errorRequestingLink:
        "Hubo un error al solicitar su enlace, por favor trate luego.",
    linkText:
        "Si no te acuerdas de tu contrasena deseas que te enviemos un link de entrada nuevo?",

    notNecesary: "No es Necesario",
    yes: "Si",
    correoInvalido: "Correo Invalido",
    cancelar: "Cancelar",
    linkDeEntrada: "Solicitar enlace de entrada",
    linkDeEntradaContrasena: "Recuperar Contraseña",

    haveUpdated: "Hemos actualizado las políticas de la empresa",
    helpUpdated: "Para proceder por favor acepte las nuevas condiciones",
    pleaseAcceptTerms: "Acepto los términos y condiciones",
    pleaseAcceptCookiePolicy: "Acepto las politicas de cookies",
    ternsAndConditions: "Términos y condiciones",
    cookiePolicy: "Politica de Cookie",
    logoutAndDontAccept: "Logout y no usar la plataform",
    Accept: "Aceptar",
    loading: "Cargando",
    newClient: "Si eres un cliente nuevo",
    registerNow: "Regístrate ahora",
    requestLoginLink: "solicitar un enlace de acceso por correo",
    ifYouPrefer: "Si prefiere, puede",
};
