export default {
	title: "Noritex - Ajuda com reclamações",
	description: "Ajuda sobre reclamações da Noritex",
	header: "Ajude-nos a melhorar",
	subheader: "Seu feedback é importante",
	text1: "As reclamações deverão ser geradas",
	text2: "somente a partir da página da Web, na seção",
	text3: "”Minhas Reclamações”",
	benefits: "Benefícios dessa modalidade",
	benefits1: "Redução do tempo de espera",
	benefits2: "Acessibilidade ao status de sua reivindicação",
	benefits3: "Gerenciamento mais rápido de incidentes",
	tools: "Ferramentas de Ajuda",
	tools1: "Política de Reclamações",
	tools2: "Tutorial em Vídeo",
	tools3: "Manual do Usuário",
};
