export default {
    title: "Más de 1500 recursos para descargar",
    placeholder: "¿Qué estás buscando?",
    latest: "Lo mas Reciente",
    images: "Fotos",
    videos: "Videos",
    blogs: "Blogs",
    branding: "Branding",
    catalogs: "Catálogos",
    allBrands: "Todas las Marcas",
    nothingFound: "No se encontró contenido multimedia con esta búsqueda",
    recursos: "Recursos",
    marcas: "Marcas",
    filtrar: "Filtrar",
    descargar: "Descargar",
	categories: "Categorías",
	collections: "Colecciones",
	campaigns: "Campañas",
};
