import styled from 'styled-components';
import { Title } from "../sdk/Layout";
import { Tabs } from "../sdk/Elements";

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${Title} {
        margin-bottom: 0;
    }

    @media (max-width: 480px) {
        flex-direction: column;
        ${Title} {
            margin-bottom: 20px;
        }
    }
`;

export const TabsExtended = styled(Tabs)`
    margin: 30px 0;
    margin-bottom: ${props => props.marginBottom || 30}px;
    ul {
        width: 100%;
    }
    @media (max-width: 768px) {
        margin: 30px 0;
    }
`;

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  tr {
      th, td {
          vertical-align: middle;
          padding: 10px;
          border-top: solid 1px ${(props) => props.theme.borderColor};
          border-bottom: solid 1px ${(props) => props.theme.borderColor};
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
      }
      th {
        font-size: 14px;
        font-weight: bold;
        white-space: nowrap;
      }
  }
`;


export const ClaimInfo = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    gap: 50px;
  .item {
      h4 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        color: ${(props) => props.theme.gray};
        white-space: nowrap;
    }
    > div {
        padding-top: 5px;
        p {
            font-size: 14px;
        }
    }
  }
  @media (max-width: 480px) {
    gap: 20px;
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

export const ProductRow = styled.div`
    padding: 10px 0;
    border-top: solid 1px ${(props) => props.theme.borderColor};
    &:last-child { 
        border-bottom: solid 1px ${(props) => props.theme.borderColor}; 
    }
    .content {
        .info {
            display: flex;
            align-items: top;
            height: 100%;
            gap: 50px;
            flex-wrap: wrap;
            .item {
                  position: relative;
                  min-height: 40px;
                h4 {
                  text-transform: uppercase;
                  font-weight: bold;
                  font-size: 10px;
                  color: ${(props) => props.theme.gray};
                  white-space: nowrap;
              }
              > div {
                  padding-top: 5px;
                  display: flex;
                  align-items: center;
                  p {
                      font-size: 14px;
                  }
              }
              .image {
                  width: 40px;
                  height: 40px;
                  flex: none;
                  border: solid 1px ${(props) => props.theme.borderColor};
                  margin-right: 10px;
                  border-radius: 2px;
                  padding: 5px;
                  background-color: white;
                  img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      display: block;
                  }
              }
              &.has-image {
                  padding-left: 50px;
                  .image {
                      position: absolute;
                      left: 0;
                      top: 0;
                  }
              }
            }
            @media (max-width: 768px) {
                gap: 0;
                .item {
                    width: 48%;
                    margin: 1%;
                }
            }
        }
    
        .gallery-trigger {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
            gap: 10px;
            cursor: pointer;
            color: ${(props) => props.theme.gray};
            &:hover {
                color: ${(props) => props.theme.black};
            }
            .item {
                width: 30px;
                height: 30px;
                flex: none;
                display: flex;
                align-items: center;
                justify-content: center;
                border: solid 1px ${(props) => props.theme.borderColor};
                border-radius: 2px;
                padding: 2px;
                background-color: white;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                }
                &.indicator {
                    svg {
                        width: 12px !important;
                        height: 12px !important;
                    }
                }
            }
        }
    }

    .gallery {
        display: flex;
        height: 100%;
        align-items: center;
        gap: 20px;
        padding: 30px 0px 20px 0;
        flex-wrap: wrap;
        .item {
            width: 100px;
            height: 100px;
            border-radius: 3px;
            padding: 5px;
            background-color: white;
            border: solid 1px ${(props) => props.theme.borderColor};
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                display: block;
            }
        }
        @media (max-width: 768px) {
            gap: 10;
            .item {
                width: 60px;
                height: 60px;
            }
        }
    }
`;

export const Scrollable = styled.div`
  overflow-x: auto;
`;