import styled from "styled-components";
import { Container, Box, Section } from "../sdk/Layout";
import { Message } from "../sdk/Elements";
import { Buttons, Button } from "../sdk/Button";
import { Control } from "../sdk/Form";

export const Center = styled.div`
    margin: 30px 0 50px;
`;
export const ButtonExtended = styled(Button)`
    opacity: 1;
    color: ${(props) => props.theme.white} !important;
`;

export const Header = styled.div`
    h3 {
        font-size: 22px;
        color: ${(props) => props.theme.primaryDark};
        margin-bottom: 5px;
    }
    p {
        font-size: 15px;
        line-height: 22px;
        color: ${(props) => props.theme.primaryDark};
    }
`;

export const SmallContainer = styled(Container)`
    width: 98%;
    max-width: 700px !important;
    display: block;
    margin: auto;
    flex: none;
    p {
        font-size: 16px;
        text-align: center;
        margin: 0px 0 30px;
        color: ${(props) => props.theme.primaryDark};
    }
    .wrapper {
        border: solid 1px ${(props) => props.theme.borderColor};
        padding: 50px;
        border-radius: 3px;
        margin: auto;
        margin-bottom: 30px;
        background-color: #fff;
        box-shadow: 0 16px 18px -11px rgba(0, 0, 0, 0.05);
    }
    @media (max-width: 768px) {
        p {
            font-size: 14px;
        }
        .wrapper {
            padding: 20px;
        }
    }
`;

export const InfoSection = styled.div`
    margin-top: 30px;
    text-align: center;
    a {
        font-size: 14px;
        color: ${(props) => props.theme.primaryDark};
        text-decoration: underline;
    }
    a + a {
        margin-left: 20px;
    }
`;

export const StandAloneLoader = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 990;
    display: none;
    background-color: #fff;
    > div {
        @-webkit-keyframes spinAround {
            from {
                transform: rotate(0deg);
            }
        }
        @keyframes spinAround {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(359deg);
            }
        }
        color: transparent !important;
        pointer-events: none;
        &::after {
            -webkit-animation: spinAround 500ms infinite linear;
            animation: spinAround 500ms infinite linear;
            border: 3px solid #dbdbdb;
            border-radius: 290486px;
            border-right-color: transparent;
            border-top-color: transparent;
            content: "";
            display: block;
            height: 2em;
            position: relative;
            width: 2em;
            position: absolute;
            left: calc(50% - (2em / 2));
            top: calc(50% - (2em / 2));
            position: absolute !important;
        }
    }
    @media (max-width: 980px) ${(props) =>
            !props.theme.isStandalone ? "and (display-mode: standalone)" : ""} {
        display: block;
    }
`;

export const AskForLink = styled(Message)`
    margin-top: 10px;
    ${Buttons} {
        margin-top: 10px;
    }
`;

export const Wrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const BoxExtended = styled(Box)`
    width: 98%;
    max-width: 620px;
    padding: 50px;
`;

export const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    svg {
        width: 120px;
        fill: ${(props) => props.theme.darkMode && "white"};
    }
`;

export const SectionExtended = styled(Section)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 550px);
    padding: 5% 0;
`;

export const ControlExtended = styled(Control)`
    input {
        height: 50px !important;
    }
    p {
        text-align: left !important;
        font-size: 14px !important;
        padding-top: 5px;
        margin: 0 !important;
        color: ${(props) => props.theme.primaryDark};

        a {
            text-decoration: underline;
            color: ${(props) => props.theme.primaryDark};
            opacity: ${(props) => (props.loading ? "0.7" : "1")};
            position: relative;
        }
    }
    button {
        display: ${(props) => (!props.loading ? "none" : "inline")};
        border: 0;
        position: absolute;
        padding: 0;
        margin-top: 7px;
        margin-left: 18px;
        margin-bottom: 0;
        z-index: 2;
        height: 0;
        width: 0;
    }
`;

export const Divisor = styled.div`
    border-top: solid 1px ${(props) => props.theme.borderColor};
    padding-top: 40px;
    margin-top: 40px;
`;
