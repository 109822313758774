import React from "react";
import withTracker from "../../blueberry/connect";
import MainTemplate from "../templates/MainTemplate";
import InternalLink from "../components/InternalLink";
import Blueberry from "../../blueberry/index";
import ErrorMessage from "../components/ErrorMessage/index";
import { getParameterByName } from "../utils";

import { Button, Buttons } from "../sdk/Button";
import { Control, Field } from "../sdk/Form";
import { Section, Heading } from "../sdk/Layout";
import { SmallContainer, AskForLink } from "./style";
import { isEmailValid } from "../utils";

import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";

function getLang(lang) {
	switch (lang) {
		case "es":
			return lang_es;
		case "en":
			return lang_en;
		case "pt":
			return lang_pt;
	}
}

class AccountRecovery extends MainTemplate {
	constructor(props) {
		super(props);

		const redirect_url = props.location.search
			? getParameterByName("redirect_url", props.location.search)
			: null;

		this.state = {
			email: "",
			redirect_url: decodeURIComponent(redirect_url),
			password: "",
			success: false,
			offerMagicLink: true,
			isRequestingLink: false,
			passwordError: [],
		};
	}

	onSubmit = (e) => {
		e.preventDefault();
		const { email, password } = this.state;
		const self = this;
		if (this.props.match.params.token) {
			this.setState({ isLoading: true });
			Blueberry.resetPassword({
				token: this.props.match.params.token,
				password,
			})
				.then((userId) => {
					self.onSuccess(userId);
					self.setState({
						successMessage: "Password has been set",
						errorMessage: null,
						success: true,
						isLoading: false,
					});
				})
				.catch((error) => {
					console.log("There was an error:" + error.reason);
					console.log("There", error);
					self.setState({
						errorMessage: error.reason,
						successMessage: null,
						success: false,
						isLoading: false,
					});
				});
			return;
		}

		if (isEmailValid(email)) {
			this.setState({ isLoading: true });
			Blueberry.forgotPassword({ email })
				.then((userId) => {
					self.setState({
						successMessage: "Email was sent",
						isLoading: false,
						errorMessage: null,
					});
				})
				.catch((error) => {
					console.log("There was an error:" + error.reason);
					console.log("There", error);
					self.setState({
						errorMessage: error.reason,
						isLoading: false,
						successMessage: null,
					});
				});
		}
	};

	hideRequestForLink = () => {
		this.setState({ offerMagicLink: false });
	};

	handleLinkRequest = (e) => {
		e.preventDefault();
		this.setState({ isRequestingLink: true });
		const { email } = this.state;
		if (!isEmailValid(email)) {
			this.setState({
				isRequestingLink: false,
				errorMessage: this.getCustomT("correoInvalido"),
			});
			return;
		}
		Blueberry.requestMagicLink({ email: email.trim(), isWebsite: true })
			.then(() => {
				this.setState({
					isRequestingLink: false,
					offerMagicLink: false,
					errorMessage: null,
					successMessage: this.getCustomT("successRequestingLink"),
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					isRequestingLink: false,
					offerMagicLink: true,
					successMessage: null,
					successMessage: null,
					errorMessage:
						err && err.reason
							? err.reason
							: this.getCustomT("errorRequestingLink"),
				});
			});
	};

	getCustomT = (param) => {
		const { lang } = this.props.match.params;
		const langTerms = getLang(lang);

		return langTerms[param];
	};

	onSuccess = (error, response) => {
		const { redirect_url } = this.state;
		const lang = `/${this.props.match.params.lang}/`;

		if (
			typeof window !== "undefined" &&
			window.__REGISTERED_LOGIN_HANDLERS__
		) {
			window.__REGISTERED_LOGIN_HANDLERS__.map((index) => {
				index();
			});
		}

		if (
			redirect_url != null &&
			redirect_url !== "" &&
			redirect_url.indexOf(lang) > -1
		) {
			this.props.history.push(
				`${lang}account/check?redirect_url=${redirect_url}`,
			);
		} else {
			this.props.history.push(`${lang}account/check`);
		}
	};

	setValue = (e) => {
		let obj = {};
		let name = e.target.name;
		let value = e.target.value;
		let passwordError = [];
		if (name === "password") {
			var lowerCaseLetters = /[a-z]/g;
			var upperCaseLetters = /[A-Z]/g;
			var numbers = /[0-9]/g;
			if (!value.match(lowerCaseLetters))
				passwordError.push(this._t("passwordLowerCase"));
			if (!value.match(upperCaseLetters))
				passwordError.push(this._t("passwordUpperCase"));
			if (!value.match(numbers))
				passwordError.push(this._t("passwordNumber"));
			if (value.length < 8)
				passwordError.push(this._t("password8Characters"));
			obj["passwordError"] = passwordError;
		}
		obj[name] = value;
		this.setState(obj);
	};

	renderBody() {
		const {
			email,
			errorMessage,
			password,
			passwordError,
			isLoading,
			redirect_url,
			successMessage,
			offerMagicLink,
			isRequestingLink,
		} = this.state;

		return (
			<Section>
				<SmallContainer>
					<div id="customer_recovery" data-account-login-recovery>
						<form>
							<Heading isCentered>
								<h3>{this._t("resetPassword")}</h3>
							</Heading>
							{this.props.match.params.token ? (
								<p>{this._t("enterNewPassword")}</p>
							) : (
								<p>{this._t("resetPasswordInfo")}</p>
							)}
							{errorMessage || successMessage ? (
								<ErrorMessage
									errorMessage={
										errorMessage || successMessage
									}
									sucess={
										successMessage !== "" &&
										successMessage != null
									}
								/>
							) : null}

							{this.props.match.params.token ? (
								<Field>
									<label htmlFor="new-password">
										Password
									</label>{" "}
									<Control isExpanded>
										<input
											type="password"
											name="password"
											value={password}
											onChange={this.setValue}
											className={` ${
												passwordError.length > 0
													? " is-invalid"
													: ""
											}`}
											autoComplete="new-password"
											aria-label={"New Password"}
											id="new-password"
										/>
										<div className="invalid-feedback">
											<ul>
												{passwordError.map(
													(index, i) => {
														return (
															<li
																key={
																	"errors" + i
																}>
																{index}
															</li>
														);
													},
												)}
											</ul>
										</div>
									</Control>
								</Field>
							) : (
								<Field>
									<label htmlFor="email">Email</label>
									<Control isExpanded>
										<input
											id="email"
											name="email"
											type="email"
											autoCorrect="off"
											autoCapitalize="off"
											autoComplete="username"
											aria-label={"User Email"}
											value={email}
											onChange={this.setValue}
											style={{
												backgroundRepeat: "no-repeat",
												backgroundAttachment: "scroll",
												backgroundSize: "16px 18px",
												backgroundPosition: "98% 50%",
											}}
										/>
									</Control>
								</Field>
							)}
							<Buttons hasLink>
								<Button
									primary
									loading={isLoading}
									static={
										password === "" && !isEmailValid(email)
									}
									onClick={this.onSubmit}
									type="submit">
									{this.getCustomT("linkDeEntradaContrasena")}
								</Button>

								{/* <Button
                                    primary
                                    loading={isLoading}
                                    static={
                                        password === "" && !isEmailValid(email)
                                    }
                                    onClick={this.onSubmit}
                                    type="submit"
                                >
                                    {this.getCustomT("linkDeEntrada")}
                                </Button> */}

								<InternalLink
									type="button"
									to={`/account/login?redirect_url=${redirect_url}`}>
									<Button>
										{this.getCustomT("cancelar")}
									</Button>
								</InternalLink>
							</Buttons>
							{/* {offerMagicLink && (
                                <AskForLink>
                                    {this.getCustomT("linkText")}
                                    <Buttons>
                                        <Button
                                            onClick={this.hideRequestForLink}
                                        >
                                            {this.getCustomT("notNecesary")}
                                        </Button>
                                        <Button
                                            primary
                                            onClick={this.handleLinkRequest}
                                            loading={isRequestingLink}
                                        >
                                            {this.getCustomT("yes")}
                                        </Button>
                                    </Buttons>
                                </AskForLink>
                            )} */}
						</form>
					</div>
				</SmallContainer>
			</Section>
		);
	}
}

export default withTracker({})(AccountRecovery);
