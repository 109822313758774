/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-restricted-globals */
import React from "react";
import Helmet from "react-helmet";
import MainTemplate from "../templates/MainTemplate";
import {
	Container, Section, Title, Spacer, BlankState,
} from "../sdk/Layout";
import { Message, Tag, Preloader } from "../sdk/Elements";
import { Button } from "../sdk/Button";
import {
	Header, TabsExtended, Table, Scrollable,
} from "./style";
import ClaimDetails from "./ClaimDetails";

import withTracker from "../../blueberry/connectPromise";
import Blueberry from "../../blueberry/index";
import { getDate, numberWithCommas } from "../utils";

import langEs from "../page/NewClaim/lang/lang_es";
import langEn from "../page/NewClaim/lang/lang_en";
import langPt from "../page/NewClaim/lang/lang_pt";
import Icons from "../components/Icons";

const getLangJson = (lang) => {
	if (lang === "es") return langEs;
	if (lang === "pt") return langPt;

	return langEn;
};

const getTotalClaim = (row) => {
	let sumCredit = 0;
	row.issues.forEach((issue) => {
		sumCredit += issue.quantity * issue.price;
	});

	return `$${numberWithCommas(sumCredit)}`;
};

const getTotalBill = (row) => {
	let total = 0;
	const bill = row.billInformation;
	if (bill && bill.length > 0) {
		total = bill[0].Monto ?? 0;
	}

	return total ? `$${numberWithCommas(total)}` : "";
};

const getTotalCreditNote = (row) => {
	if (row.claimStatus === "closed") return `$${numberWithCommas(row.claimTotal)}`;
	if (row.claimStatus === "declined") return "$0.00";

	return "";
};

const getPropColor = (status) => {
	if (status === "inProgress") return { alert: true };
	if (status === "requireApproved") return { alert: true };
	if (status === "closed") return { success: true };
	if (status === "declined") return { error: true };

	return { info: true };
};

class ClaimsView extends MainTemplate {
	constructor(props) {
		super(props);
		// const lastKnownProduct = Blueberry.getLastKnownProduct();
		this.state = {
			claims: [],
			current: {},
			details: false,
			activeTab: "all",
			isReady: false,
		};

		this.handleClick = this.handleClick.bind(this);
		this.goToBills = this.goToBills.bind(this);
		this.goBack = this.goBack.bind(this);
		this.handleFilterClick = this.handleFilterClick.bind(this);

		// language
		const { lang, brandSlug, claimId } = this.props.match.params;
		this.lang = getLangJson(lang);
		this.brandSlug = brandSlug;
		this.claimId = claimId;
	}

	async componentDidMount() {
		if (this.props.store.user.id != null) {
			const claims = await Blueberry.getClaimsFromUser({
				claimStatus: "all",
				brandSlug: this.brandSlug,
			});
			this.setState({ ...this.state, claims });
		}
	}

	async componentDidUpdate() {
		if (this.props.store.user.id != null && !this.state.isReady) {
			const claims = await Blueberry.getClaimsFromUser({
				claimStatus: "all",
				brandSlug: this.brandSlug,
			});
			const current = this.claimId
				? claims.find((claim) => claim._id === this.claimId)
				: {};
			this.setState({
				...this.state,
				claims,
				current,
				details: !!this.claimId,
				isReady: true,
			});
		}
	}

	async handleFilterClick(e, option) {
		e.preventDefault();
		const claims = await Blueberry.getClaimsFromUser({
			claimStatus: option,
		});
		this.setState({ ...this.state, claims, activeTab: option });
	}

	handleClick(e, row) {
		const { lang, brandSlug } = this.props.match.params;
		this.props.history.push(
			`/${lang}${brandSlug ? `/${brandSlug}` : ""}/claims/${row._id}`,
		);
		location.reload();
	}

	goBack() {
		const { lang, brandSlug } = this.props.match.params;
		this.props.history.push(
			`/${lang}${brandSlug ? `/${brandSlug}` : ""}/claims`,
		);
		location.reload();
	}

	goToBills() {
		const { lang } = this.props.match.params;
		this.props.history.push(`/${lang}/orders/?view=bill&from=claims`);
	}

	renderBody() {
		if (this.claimId && this.state.claims.length > 0) {
			console.log("this.claimId is", this.claimId);
			return (
				<ClaimDetails
					{...this.props}
					detail={this.state.current}
					lang={this.lang}
					goBack={this.goBack}
				/>
			);
		}

		return (
			<>
				<Helmet>
					<title>{this.lang.myClaims}</title>
				</Helmet>

				<Container>
					<Section>
						<Header>
							<Title size="4">{this.lang.myClaims}</Title>
							{this.state.isReady ? (
								<div>
									<Button onClick={this.goToBills} primary>
										{this.lang.beginNewClaim}
									</Button>
								</div>
							) : null}
						</Header>

						{!this.state.details ? (
							<>
								{this.state.isReady ? (
									<TabsExtended marginBottom={10}>
										<ul>
											<li
												className={
													this.state.activeTab === "all"
														? "is-active"
														: ""
												}
											>
												<a
													href="#"
													onClick={(e) => this.handleFilterClick(e, "all")}
												>
													{this.lang.filterAll}
												</a>
											</li>
											<li
												className={
													this.state.activeTab === "open"
														? "is-active"
														: ""
												}
											>
												<a
													href="#"
													onClick={(e) => this.handleFilterClick(e, "open")}
												>
													{this.lang.filterOpen}
												</a>
											</li>
											<li
												className={
													this.state.activeTab === "inProgress"
														? "is-active"
														: ""
												}
											>
												<a
													href="#"
													onClick={(e) => this.handleFilterClick(e, "inProgress")}
												>
													{this.lang.filerProcess}
												</a>
											</li>
											<li
												className={
													this.state.activeTab === "closed"
														? "is-active"
														: ""
												}
											>
												<a
													href="#"
													onClick={(e) => this.handleFilterClick(e, "closed")}
												>
													{this.lang.filterClosed}
												</a>
											</li>
											<li
												className={
													this.state.activeTab === "declined"
														? "is-active"
														: ""
												}
											>
												<a
													href="#"
													onClick={(e) => this.handleFilterClick(e, "declined")}
												>
													{this.lang.filterDeclined}
												</a>
											</li>
										</ul>
									</TabsExtended>
								) : (
									<>
										<Spacer height={10} />
										<Preloader />
									</>
								)}

								{this.state.activeTab === "open" ? (
									<Message className="has-text-centered" info>
										{this.lang.statusOpen}
									</Message>
								) : null}
								{this.state.activeTab === "closed" ? (
									<Message
										className="has-text-centered"
										success
									>
										{this.lang.statusClosed}
									</Message>
								) : null}
								{this.state.activeTab === "inProgress" ? (
									<Message
										className="has-text-centered"
										alert
									>
										{this.lang.statusInProgress}
									</Message>
								) : null}
								{this.state.activeTab === "declined" ? (
									<Message
										className="has-text-centered"
										error
									>
										{this.lang.statusDeclined}
									</Message>
								) : null}

								<Spacer height={10} />

								{this.state.claims.length > 0 ? (
									<Scrollable>
										<Table>
											<thead>
												<tr>
													<th>
														{this.lang.headerClaim}
													</th>
													<th>
														{this.lang.headerBill}
													</th>
													<th>
														{this.lang.headerDate}
													</th>
													<th>
														{this.lang.headerStatus}
													</th>
													<th>
														{this.lang.headerTotalBill}
													</th>
													<th>
														{this.lang.headerTotal}
													</th>
													<th>
														{
															this.lang
																.headerTotalCreditNote
														}
													</th>
													<th width="1">&nbsp;</th>
												</tr>
											</thead>
											<tbody>
												{this.state.claims.map(
													(row) => (
														<tr key={row._id}>
															<td>
																{row._id
																	.slice(-5)
																	.toUpperCase()}
															</td>
															<td>
																{row.orderNumber.toUpperCase()}
															</td>
															<td>
																{getDate(
																	row.createdAt,
																)}
															</td>
															<td>
																<Tag
																	{...getPropColor(
																		row.claimStatus,
																	)}
																>
																	{
																		this
																			.lang
																			.claimOptionStatus[row.claimStatus]
																	}
																</Tag>
															</td>
															<td>
																{ getTotalBill(row) }
															</td>
															<td>
																{getTotalClaim(
																	row,
																)}
															</td>
															<td>
																{ getTotalCreditNote(row) }
															</td>
															<td>
																<Button
																	small
																	white
																	onClick={(e) => this.handleClick(e, row)}
																>
																	{
																		this
																			.lang
																			.details
																	}
																</Button>
															</td>
														</tr>
													),
												)}
											</tbody>
										</Table>
									</Scrollable>
								) : this.state.isReady ? (
									<BlankState>
										<div>
											<Icons icon="text-box-edit-outline" />
											<h3>{this.lang.noDataTitle}</h3>
											<p>{this.lang.noData}</p>
											<a
												onClick={this.goToBills}
												href="#"
											>
												{this.lang.beginNewClaim}
											</a>
										</div>
									</BlankState>
								) : (
									<div>
										<Preloader />
										<Preloader />
									</div>
								)}
							</>
						) : null}
					</Section>
				</Container>
			</>
		);
	}
}

export default withTracker()(ClaimsView);
