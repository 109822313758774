import styled from "styled-components";
import { Pagination } from "../../sdk/Elements";
export const PaginationExtended = styled(Pagination)`
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            a {
                font-size: 2rem;
            }
        }
    }
`;

export const PaginationWrapper = styled.div`
    flex-direction: column;
    align-items: center;
`;
export const InfoSection = styled.div`
    text-align: center;
    font-size: 13px;
    margin: 13px 0;
    color: ${(props) => props.theme.gray};
`;
