export default {
	title: "Noritex - Claims Help",
	description: "Noritex Claims Help",
	header: "Help us to improve",
	subheader: "Your feedback is important",
	text1: "The claims must be generated",
	text2: "only from the web page, in the section",
	text3: "“My Claims”",
	benefits: "Benefits of this modality",
	benefits1: "Reduction of waiting time",
	benefits2: "Accessibility to the status of your claim",
	benefits3: "Fastest incident management",
	tools: "Helping Tools",
	tools1: "Claims Policy",
	tools2: "Video Tutorial",
	tools3: "User Manual",
};
