import React from "react";

function Icons({ icon, className }) {
	if (icon === "monitor-arrow-down") {
		return (
			<svg
				className={className}
				style={{ width: 24, height: 24 }}
				viewBox="0 0 24 24"
			>
				<path
					fill="currentColor"
					d="M21 16H3V4H21M21 2H3C1.9 2 1 2.9 1 4V16C1 17.1 1.9 18 3 18H10V20H8V22H16V20H14V18H21C22.1 18 23 17.1 23 16V4C23 2.9 22.1 2 21 2M16 10H13V6H11V10H8L12 14L16 10"
				/>
			</svg>
		);
	}

	if (icon === "image-frame") {
		return (
			<svg
				className={className}
				style={{ width: 24, height: 24 }}
				viewBox="0 0 24 24"
			>
				<path
					fill="currentColor"
					d="M10,14.29L6.5,19H17.46L14.75,15.46L12.78,17.8L10,14.29M5,21V7H18.96V21H5M12,2.4L14.61,5.03H9.37L12,2.4M5,5.03C4.5,5.03 4,5.22 3.61,5.61C3.2,6 3,6.46 3,7V21C3,21.5 3.2,22 3.61,22.39C4,22.8 4.5,23 5,23H18.96C19.5,23 19.96,22.8 20.37,22.39C20.77,22 21,21.5 21,21V7C21,6.46 20.77,6 20.37,5.61C19.96,5.22 19.5,5.03 18.96,5.03H16L12,1L7.96,5.03H5Z"
				/>
			</svg>
		);
	}

	if (icon === "sofa-outline") {
		return (
			<svg
				className={className}
				style={{ width: 24, height: 24 }}
				viewBox="0 0 24 24"
			>
				<path
					fill="currentColor"
					d="M21 9V7C21 5.35 19.65 4 18 4H14C13.23 4 12.53 4.3 12 4.78C11.47 4.3 10.77 4 10 4H6C4.35 4 3 5.35 3 7V9C1.35 9 0 10.35 0 12V17C0 18.65 1.35 20 3 20V22H5V20H19V22H21V20C22.65 20 24 18.65 24 17V12C24 10.35 22.65 9 21 9M14 6H18C18.55 6 19 6.45 19 7V9.78C18.39 10.33 18 11.12 18 12V14H13V7C13 6.45 13.45 6 14 6M5 7C5 6.45 5.45 6 6 6H10C10.55 6 11 6.45 11 7V14H6V12C6 11.12 5.61 10.33 5 9.78V7M22 17C22 17.55 21.55 18 21 18H3C2.45 18 2 17.55 2 17V12C2 11.45 2.45 11 3 11S4 11.45 4 12V16H20V12C20 11.45 20.45 11 21 11S22 11.45 22 12V17Z"
				/>
			</svg>
		);
	}

	if (icon === "help-circle-outline") {
		return (
			<svg
				className={className}
				style={{ width: 24, height: 24 }}
				viewBox="0 0 24 24"
			>
				<path
					fill="currentColor"
					d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"
				/>
			</svg>
		);
	}

	if (icon === "school-outline") {
		return (
			<svg
				className={className}
				style={{ width: 24, height: 24 }}
				viewBox="0 0 24 24"
			>
				<path
					fill="currentColor"
					d="M12 3L1 9L5 11.18V17.18L12 21L19 17.18V11.18L21 10.09V17H23V9L12 3M18.82 9L12 12.72L5.18 9L12 5.28L18.82 9M17 16L12 18.72L7 16V12.27L12 15L17 12.27V16Z"
				/>
			</svg>
		);
	}

	if (icon === "text-box-edit-outline") {
		return (
			<svg
				className={className}
				// style={{ width: 24, height: 24 }}
				viewBox="0 0 24 24"
			>
				<path
					fill="currentColor"
					d="M10 21H5C3.89 21 3 20.11 3 19V5C3 3.89 3.89 3 5 3H19C20.11 3 21 3.89 21 5V10.33C20.7 10.21 20.37 10.14 20.04 10.14C19.67 10.14 19.32 10.22 19 10.37V5H5V19H10.11L10 19.11V21M7 9H17V7H7V9M7 17H12.11L14 15.12V15H7V17M7 13H16.12L17 12.12V11H7V13M21.7 13.58L20.42 12.3C20.21 12.09 19.86 12.09 19.65 12.3L18.65 13.3L20.7 15.35L21.7 14.35C21.91 14.14 21.91 13.79 21.7 13.58M12 22H14.06L20.11 15.93L18.06 13.88L12 19.94V22Z"
				/>
			</svg>
		);
	}

	if (icon === "arrow-right") {
		return (
			<svg
				className={className}
				style={{ width: 24, height: 24 }}
				viewBox="0 0 24 24"
			>
				<path
					fill="currentColor"
					d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
				/>
			</svg>
		);
	}
	
	if (icon === "image-file-download") {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				id="Image-File-Download--Streamline-Ultimate">
				<defs />
				<path
					d="M19.207 4.5a1 1 0 0 1 0.293 0.707V22.5a1 1 0 0 1 -1 1h-17a1 1 0 0 1 -1 -1v-21a1 1 0 0 1 1 -1h13.293a1 1 0 0 1 0.707 0.293Z"
					fill="#eef8ff"
					strokeWidth={1}
				/>
				<path
					d="M19.207 4.5 15.5 0.793A1 1 0 0 0 14.793 0.5H1.5a1 1 0 0 0 -1 1v21a1 1 0 0 0 0.293 0.707L19.324 4.676a0.945 0.945 0 0 0 -0.117 -0.176Z"
					fill="#ffffff"
					strokeWidth={1}
				/>
				<path
					d="M4.505 7.5a2 2 0 1 0 4 0 2 2 0 1 0 -4 0"
					fill="#469df2"
					strokeWidth={1}
				/>
				<path
					d="M11.929 10.178a0.5 0.5 0 0 0 -0.848 0L8.443 14.4l-1.076 -1.722a0.5 0.5 0 0 0 -0.848 0L3.505 17.5h13Z"
					fill="#469df2"
					strokeWidth={1}
				/>
				<path
					d="M4.5 7.5a2 2 0 1 0 4 0 2 2 0 1 0 -4 0"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="m12.544 11.17 -0.62 -0.992a0.5 0.5 0 0 0 -0.848 0L8.438 14.4l-1.076 -1.722a0.5 0.5 0 0 0 -0.848 0L3.5 17.5h5"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="M10.5 23.5h-9a1 1 0 0 1 -1 -1v-21a1 1 0 0 1 1 -1h13.293a1 1 0 0 1 0.707 0.293L19.207 4.5a1 1 0 0 1 0.293 0.707V9.5"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="M17.5 23.5a6 6 0 1 0 -6 -6 6 6 0 0 0 6 6Z"
					fill="#469df2"
					strokeWidth={1}
				/>
				<path
					d="M17.5 11.5a6 6 0 0 0 -3.943 10.516l8.458 -8.458A5.977 5.977 0 0 0 17.5 11.5Z"
					fill="#eef8ff"
					strokeWidth={1}
				/>
				<path
					d="M17.5 23.5a6 6 0 1 0 -6 -6 6 6 0 0 0 6 6Z"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="M17.5 14.5v6"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="m17.5 20.5 -2.25 -2.25"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="m17.5 20.5 2.25 -2.25"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
			</svg>
		);
	}

	if (icon === "table-lamp-2") {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				id="Table-Lamp-2--Streamline-Ultimate">
				<defs />
				<path
					d="M18.887 14.5H5.113a1 1 0 0 1 -0.994 -1.107L5.41 1.4a1 1 0 0 1 1 -0.9H17.6a1 1 0 0 1 1 0.9l1.291 11.993a1 1 0 0 1 -1.004 1.107Z"
					fill="#469df2"
					strokeWidth={1}
				/>
				<path
					d="m10.278 14.5 -0.671 3.017a2.451 2.451 0 1 0 4.786 0l-0.671 -3.017"
					fill="#469df2"
					strokeWidth={1}
				/>
				<path
					d="M7 23.5c0 -1.105 2 -3 5 -3s5 1.895 5 3Z"
					fill="#469df2"
					strokeWidth={1}
				/>
				<path
					d="M12 0.5H6.405a1 1 0 0 0 -1 0.9L4.119 13.393a1 1 0 0 0 0.994 1.107H12Z"
					fill="#eef8ff"
					strokeWidth={1}
				/>
				<path
					d="m14.248 16.864 -0.526 -2.364 -3.444 0 -0.526 2.364 4.496 0z"
					fill="#469df2"
					strokeWidth={1}
				/>
				<path
					d="M18.9 14.5H5.127a1 1 0 0 1 -0.994 -1.107L5.424 1.4a1 1 0 0 1 0.995 -0.9h11.19a1 1 0 0 1 0.995 0.9L19.9 13.393a1 1 0 0 1 -1 1.107Z"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="m10.292 14.5 -0.671 3.017a2.451 2.451 0 1 0 4.785 0l-0.67 -3.017"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="M7.014 23.5c0 -1.105 2 -3 5 -3s5 1.895 5 3Z"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
			</svg>
		);
	}

	if ( icon === "artboard-shapes" ) {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				id="Artboard-Shapes--Streamline-Ultimate">
				<defs />
				<path
					d="M5 1v2"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="M3 5H1"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="M3 19H1"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="M19 1v2"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="M5 21v2"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="M19 21v2"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="M23 5h-2"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="M23 19h-2"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path d="M5 5h14v14H5Z" fill="#ffffff" strokeWidth={1} />
				<path
					d="M11 14H8.5a0.5 0.5 0 0 1 -0.5 -0.5v-5a0.5 0.5 0 0 1 0.5 -0.5h4a0.5 0.5 0 0 1 0.5 0.5V10"
					fill="#469df2"
					strokeWidth={1}
				/>
				<path
					d="M11 10.5a0.5 0.5 0 0 1 0.5 -0.5h4a0.5 0.5 0 0 1 0.5 0.5v5a0.5 0.5 0 0 1 -0.5 0.5h-4a0.5 0.5 0 0 1 -0.5 -0.5Z"
					fill="#469df2"
					strokeWidth={1}
				/>
				<path
					d="M5 5h14v14H5Z"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="M11 14H8.5a0.5 0.5 0 0 1 -0.5 -0.5v-5a0.5 0.5 0 0 1 0.5 -0.5h4a0.5 0.5 0 0 1 0.5 0.5V10h0"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="M11 10.5a0.5 0.5 0 0 1 0.5 -0.5h4a0.5 0.5 0 0 1 0.5 0.5v5a0.5 0.5 0 0 1 -0.5 0.5h-4a0.5 0.5 0 0 1 -0.5 -0.5Z"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
			</svg>
		);
	}

	if ( icon === "help-wheel" ) {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				id="Help-Wheel--Streamline-Ultimate">
				<defs />
				<path
					d="M12 0.5A11.5 11.5 0 1 0 23.5 12 11.5 11.5 0 0 0 12 0.5ZM12 18a6 6 0 1 1 6 -6 6 6 0 0 1 -6 6Z"
					fill="#eef8ff"
					strokeWidth={1}
				/>
				<path
					d="M12 18a6 6 0 0 1 0 -12V0.5a11.5 11.5 0 0 0 0 23Z"
					fill="#ffffff"
					strokeWidth={1}
				/>
				<path
					d="M9.428 6.585 5.419 2.577a11.563 11.563 0 0 0 -2.832 2.828l4.006 4.007a6.015 6.015 0 0 1 2.835 -2.827Z"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="#469df2"
					strokeWidth={1}
				/>
				<path
					d="m17.411 9.421 4.008 -4.007a11.552 11.552 0 0 0 -2.833 -2.833l-4.008 4.007a6.01 6.01 0 0 1 2.833 2.833Z"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="#469df2"
					strokeWidth={1}
				/>
				<path
					d="m14.591 17.405 4.009 4.006a11.538 11.538 0 0 0 2.826 -2.828l-4.009 -4.009a6.006 6.006 0 0 1 -2.826 2.831Z"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="#469df2"
					strokeWidth={1}
				/>
				<path
					d="m6.591 14.584 -4.007 4.007a11.589 11.589 0 0 0 2.83 2.828l4.008 -4.008a6.016 6.016 0 0 1 -2.831 -2.827Z"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="#469df2"
					strokeWidth={1}
				/>
				<path
					d="M12 0.5A11.5 11.5 0 1 0 23.5 12 11.5 11.5 0 0 0 12 0.5ZM12 18a6 6 0 1 1 6 -6 6 6 0 0 1 -6 6Z"
					fill="none"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
			</svg>
		);
	}

	if ( icon === "e-learning-monitor" ) {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				id="E-Learning-Monitor--Streamline-Ultimate">
				<defs />
				<path
					d="M1.5 2.001h21s1 0 1 1v15s0 1 -1 1h-21s-1 0 -1 -1v-15s0 -1 1 -1"
					fill="#469df2"
					strokeWidth={1}
				/>
				<path
					d="M1.5 2a1 1 0 0 0 -1 1v15a0.978 0.978 0 0 0 0.229 0.612L17.34 2Z"
					fill="#eef8ff"
					strokeWidth={1}
				/>
				<path
					d="M1.5 2.001h21s1 0 1 1v15s0 1 -1 1h-21s-1 0 -1 -1v-15s0 -1 1 -1"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="none"
					strokeWidth={1}
				/>
				<path
					d="m7 22.001 10 0"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="none"
					strokeWidth={1}
				/>
				<path
					d="M10.5 19.001h3v3h-3Z"
					fill="#eef8ff"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="M8.5 9.787v3.4a0.5 0.5 0 0 0 0.276 0.448l3.277 1.638a1 1 0 0 0 0.894 0l3.277 -1.638a0.5 0.5 0 0 0 0.276 -0.448v-3.4"
					fill="#eef8ff"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="m5.5 8.501 0 3.5"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="none"
					strokeWidth={1}
				/>
				<path
					d="m5.5 12 -1.373 3.664a0.249 0.249 0 0 0 0.234 0.336h2.278a0.249 0.249 0 0 0 0.234 -0.337L5.5 12"
					fill="#469df2"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="m12.894 11.333 5.534 -2.372a0.5 0.5 0 0 0 0 -0.919L12.894 5.67a1 1 0 0 0 -0.788 0L5.5 8.5l6.606 2.832a1.007 1.007 0 0 0 0.788 0.001Z"
					fill="#469df2"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
			</svg>
		);
	}

	if ( icon === "megaphone" ) {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				id="Megaphone--Streamline-Ultimate">
				<defs />
				<path
					d="M7.59 6.2768V14.7587C7.5899 17.438 9.109 19.8856 11.51 21.0748"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="none"
					strokeWidth={1}
				/>
				<path
					d="M21.31 8.3348H21.8C22.6118 8.3348 23.27 8.993 23.27 9.8048V10.7848C23.27 11.5966 22.6118 12.2548 21.8 12.2548H21.31Z"
					fill="#469df2"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="M20.8572 3.0791C20.5752 2.8991 20.2209 2.8755 19.9174 3.0163C16.0839 4.9596 11.883 6.0707 7.59 6.2768V14.3128C11.8828 14.518 16.0837 15.6281 19.9174 17.5703C20.5669 17.8718 21.31 17.3975 21.31 16.6815V3.9052C21.31 3.5706 21.1393 3.2591 20.8572 3.0791Z"
					fill="#eef8ff"
					strokeWidth={1}
				/>
				<path
					d="M21.31 10.2948V3.9052C21.31 3.1891 20.5669 2.7149 19.9174 3.0163C16.0841 4.9601 11.883 6.0712 7.59 6.2768V10.2948Z"
					fill="#ffffff"
					strokeWidth={1}
				/>
				<path
					d="M20.8572 3.0791C20.5752 2.8991 20.2209 2.8755 19.9174 3.0163C16.0839 4.9596 11.883 6.0707 7.59 6.2768V14.3128C11.8828 14.518 16.0837 15.6281 19.9174 17.5703C20.5669 17.8718 21.31 17.3975 21.31 16.6815V3.9052C21.31 3.5706 21.1393 3.2591 20.8572 3.0791Z"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="none"
					strokeWidth={1}
				/>
				<path
					d="M7.59 6.2768C6.6757 6.3336 5.7025 6.3689 4.65 6.3748H3.67C2.0462 6.3747 0.73 7.691 0.73 9.3148V11.2748C0.73 12.8985 2.0463 14.2148 3.67 14.2148H4.65C5.7025 14.2148 6.6757 14.255 7.59 14.3128Z"
					fill="#469df2"
					strokeWidth={1}
				/>
				<path
					d="M7.59 10.2948V6.2768C6.6757 6.3346 5.7025 6.3689 4.65 6.3748H3.67C2.0462 6.3747 0.73 7.691 0.73 9.3148V10.2948Z"
					fill="#eef8ff"
					strokeWidth={1}
				/>
				<path
					d="M7.59 6.2768C6.6757 6.3336 5.7025 6.3689 4.65 6.3748H3.67C2.0462 6.3747 0.73 7.691 0.73 9.3148V11.2748C0.73 12.8985 2.0463 14.2148 3.67 14.2148H4.65C5.7025 14.2148 6.6757 14.255 7.59 14.3128Z"
					stroke="#002760"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="none"
					strokeWidth={1}
				/>
			</svg>
		);
	}

	if ( icon === "chevron-right" ) {
		return (
			<svg
			className={className}
				width={9}
				height={12}
				viewBox="0 0 9 12"
				fill="currentColor"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M6.4219 3.43401L3.08691 0.0990295L0.592471 2.59347L3.92746 5.92846L0.362616 9.4933L2.7918 11.9225L8.85108 5.8632L6.4219 3.43401Z"
					fill="currentColor"
				/>
			</svg>
		);
	}

	return null;
}

export default Icons;
